// ==========================================================================
// Accordion content and targets
// ==========================================================================

.accordion-block {
  border-bottom: 1px solid $border;

  &:first-of-type {
    margin-top: 30px;
    border-top: 1px solid $border;
  }
}

.accordion-title {
  cursor: pointer;
  padding: 10px 0;
}

.accordion-content {
  padding-bottom: 10px;
}

.simple-accordion-container {
  border-top: 1px solid $border;

  &:first-of-type {
    margin-top: 1em;
  }

  &:last-of-type {
    margin-bottom: 1em;
    border-bottom: 1px solid $border;
  }
}

.simple-accordion-title {
  color: $black;
  // font-size: 14px;
  padding: 1em 0;
  padding-right: 28px;
  margin: 0 !important;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -12px;
    background-image: svg-load('caret_down.svg', fill=$grey1);
    background-position: 50% 50%;
  }

  &.accordion-open {
    &::after {
      background-image: svg-load('caret_up.svg', fill=$grey1);
    }
  }

  &:hover {
    color: $orange;
  }
}

.simple-accordion-content {
  display: none;
  padding-bottom: 14px;

  &.accordion-open {
    display: block;
  }
}
