// ==========================================================================
// User dashboard styles
// ==========================================================================

.user-dashboard {
  background: $blue;
  padding-top: $gutter / 2;
  padding-bottom: $gutter / 2;
  color: $white;

  @include media('<=tablet') {
    text-align: center;
  }

  @include media('<=phone') {
    .btn {
      display: block;
      width: 100%;
    }
  }
}

.ud-row {
  @include negative-margin;
  overflow: hidden;
  padding: $gutter 0;
  position: relative;

  &:first-of-type {
    &::after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: $gutter / 2;
      right: $gutter / 2;
      background: $blue-white-30;
    }
  }
}

.ud-col {
  @include pad;

  @include media('>tablet') {
    display: table-cell;
    vertical-align: top;
  }

  @include media('<=tablet') {
    @include spacing-s;
  }
}

.ud-main {
  @include media('>tablet') {
    width: 10000px;
    text-align: right;
    vertical-align: bottom;
  }

  @include media('<=tablet') {
    margin-top: 20px;
  }
}

.ud-primary-actions {
  line-height: 1;

  > .btn {
    margin-bottom: 8px;
    display: inline-block;

    @include media('>phone') {
      &:not(:first-of-type) {
        margin-left: 8px;
      }
    }
  }
}

.ud-secondary-actions {
  margin-bottom: 24px;

  > a {
    display: inline-block;
    color: inherit;
    text-decoration: underline;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}

.ud-name {
  color: inherit;
  line-height: 1;
  padding-bottom: 6px;

  @include media('>tablet') {
    white-space: nowrap;
  }
}

.ud-stat-main {
  > .ud-stat-value {
    font-size: 60px;
  }
}

.ud-stat-secondary {
  color: $blue-white-30;

  @include media('>tablet') {
    border-left: 1px solid $blue-white-30;
  }

  @include media('<=phone') {
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  > .ud-stat-value {
    font-size: 24px;
  }
}

.ud-stat-name {
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1;
  font-size: 14px;
  color: inherit;
}

.ud-stat-value {
  line-height: 1;
}
