%h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 300;

  @include media('<=phone') {
    font-size: 36px;
  }
}

%h2 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 400;
}

%h3 {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;
}

%h4 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;
}

%h5 {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}

@for $i from 1 through 5 {
  h#{$i},
  .h#{$i} {
    @extend %h#{$i};

    > a:not([class]) {
      color: inherit;

      &:hover {
        color: $orange;
        text-decoration: none !important;
      }
    }
  }
}
