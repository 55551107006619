// ==========================================================================
// Loyalty ribbon
// ==========================================================================

.loyalty-ribbon {
  background-color: $indigo-dark;
  color: $white;
  text-align: center;

  .container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    @include media('<=tablet') {
      flex-direction: column;
      padding: 0 $gutter/2;

      .loyalty-logo {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0%;
      padding: 20px 10px;
    }
  }
}

.loyalty-ribbon-middle {
  font-size: 16px;
  margin: 0 20px;

  @include media('<=tablet') {
    margin: 30px 0 20px;
  }
}

.loyalty-ribbon-lead {
  font-size: 20px;

  @include media('<=tablet') {
    margin-bottom: 10px;
  }
}

.loyalty-ribbon-cta {
  .btn {
    width: 220px;
  }
}

.loyalty-ribbon-member-info {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);

  @include media ('<=tablet') {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.member-info-top {
  font-size: 26px;
  color: $white;
}

// ==========================================================================
// Loyalty footer
// ==========================================================================


.loyalty-footer {
  @include aa;
  text-align: center;
  color: $indigo;
  position: relative;

  > * {
    position: relative;
  }

  @include media ('>tablet') {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.loyalty-visual {
  background-size: cover;
  // background-position: top center;

  @include ratio(3, 2);

  @include media ('>tablet') {
    padding-bottom: 0;

    @include divfill;
    height: 100%;
  }
}

.loyalty-info-box {
  @include media ('>tablet') {
    width: calc(50% - #{$gutter});
    margin-left: auto;
    margin-right: $gutter;
  }
}

.loyalty-info-box-header {
  background-color: $indigo-dark;
  padding-top: 30px;
  padding-bottom: 30px;
}

.loyalty-info-box-body {
  background-color: $white;
  padding: 30px;
}

.loyalty-info-box-title {
  font-size: 28px;
  margin: 0 auto 30px;

  @include media ('>tablet') {
    max-width: 85%;
  }
}

.loyalty-perks-container {
  display: flex;
  gap: 10px;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.loyalty-perk {
  font-size: 14px;
  flex: 1 0 0%;

  @include media('<=tablet') {
    margin-bottom: 30px;
  }
}

.perk-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 20px;
}

.plus-club-perks {
  background-color: $white;
}

.club-lp-perks-container {
  margin-top: 70px;
  padding-top: 70px;
  padding-bottom: 60px;
  color: $indigo;
  text-align: center;

  .perk-title {
    font-size: 28px;
    font-weight: 400;
    margin-top: 30px;
  }

  .loyalty-perk {
    font-size: 16px;
  }
}

.perk-svg {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-top: auto;
  }
}

.loyalty-cta-container {
  display: flex;
  justify-content: center;
  margin: 30px -5px 0 -5px;

  > * {
    margin-left: 5px;
    margin-right: 5px;
    width: 50%;

    @include media('<=tablet') {
      width: 100%;
      margin: 10px 0 0;
    }
  }

  @include media('<=tablet') {
    flex-direction: column;
    margin: 0;
  }
}

// ==========================================================================
// Loyalty contact box
// ==========================================================================

.loyalty-contact-box {
  margin-top: 60px;
  margin-left: $gutter;
  align-self: flex-start;
  padding: 20px;
  background-color: $grey5;
  color: $grey1;

  @include media('<=tablet') {
    align-self: auto;
    max-width: none;
    margin-right: $gutter/2;
    margin-left: $gutter/2;
    margin-top: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
}

.contact-item {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }

  a {
    color: $grey1;
    text-decoration: underline;
  }
}

// ==========================================================================
// Loyalty forms
// ==========================================================================

.loyalty-form-heading {
  background-color: $indigo-dark;
  color: $white;
  padding-top: 30px;
  padding-bottom: 30px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media ('<=phone') {
      flex-direction: column-reverse;
      text-align: center;
    }
  }

  h1 {
    line-height: 1.2;
  }

  svg {
    margin-left: 10px;

    @include media ('<=phone') {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
}

.loyalty-form-container {
  display: flex;
  justify-content: space-between;

  @include media ('<=tablet') {
    flex-direction: column;
  }

  .simple-accordion-container:first-of-type {
    margin-top: 0;
  }
}

.h2-loyalty-form {
  font-size: 28px;
  margin-top: 60px;
  margin-bottom: $gutter;
}

.loyalty-points {
  font-size: 28px;
  color: $blue;
}

// ==========================================================================
// Loyalty header
// ==========================================================================

.loyalty-header {
  position: relative;
  margin-bottom: -70px;

  @include media ('>tablet') {
    min-height: 850px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.loyalty-login-form {
  position: relative;
  margin: 0 $gutter 0 auto;
  background-color: $white;

  @include media ('<=tablet') {
    max-width: none;
    margin: 0;
  }
}

.loyalty-login-uppercase {
  text-transform: uppercase;
  color: $indigo;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}

.loyalty-form-body {
  padding: 20px;
  text-align: center;

  .form-group {
    margin-bottom: $gutter/2;
  }

  .activate-message {
    line-height: 1.4;
    text-align: center;
    color: $grey1;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  p {
    text-align: center;
  }

  a {
    margin: 15px auto 0;
  }
}

.loyalty-form-footer {
  padding: 20px;
  text-align: center;

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 15px;
    text-align: center;
  }

  .btn-blue-clear {
    color: mix($blue, $white, 70%);

    &:hover {
      color: white;
    }
  }

  a {
    margin: 15px auto 0;
  }
}

.social-login-container {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}

.social-login-btn {
  color: $white;
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}

.social-login-fb {
  background-color: #3b5898;    // fb color
}

.social-login-g {
  background-color: #dd4a39;    // google color
}

// ==========================================================================
// Loyalty benefits
// ==========================================================================

.plus-club-benefits {
  background-color: $grey5;
  color: $indigo;
  padding-top: 60px;
  margin-bottom: -70px;
}

.benefits-title {
  text-transform: uppercase;
  font-size: 36px;
  margin-bottom: 60px;
  text-align: center;

  @include media ('<=tablet') {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.benefits-tabs {
  @include media('>desktop') {
    display: none;
  }

  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  a {
    text-transform: uppercase;
    color: $grey3;
    padding-left: 10px;
    padding-right: 10px;

    &:hover,
    &.tab-open {
      color: $indigo;
    }
  }
}

.loyalty-benefits-container {
  display: flex;
}

.benefit-item {
  width: 33.33%;
  overflow: hidden;
  border: 1px solid $border;
  margin-left: $gutter/2;
  margin-right: $gutter/2;
  display: flex;
  flex-direction: column;

  @include media('<=desktop') {
    display: none;

    &.tab-open {
      display: flex;
      width: 100%;
    }
  }
}

.benefit-visual {
  position: relative;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';

    @include ratio(1.75, 1);
  }
}

.benefit-item-body {
  padding: 20px;
  background-color: $white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .btn {
    margin-top: auto;
  }
}

.benefit-item-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;

  @include media('>tablet') {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.benefit-condition {
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.benefits-list {
  border-top: 1px solid $border;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 10px;

  li {
    margin-bottom: 10px;
    padding-left: 34px;
    position: relative;

    &::before {
      content: svg-load('material/check.svg', fill=$indigo);
      position: absolute;
      top: 0;
      left: 0;
    }

    .tooltip-target {
      color: $grey4;
    }
  }
}

.faq-info {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  a {
    margin-top: 20px;
  }
}

// ==========================================================================
// Club sidebar
// ==========================================================================

.club-sidebar {
  background-color: $grey5;
  margin-bottom: $gutter;
  margin-left: $gutter;

  @include media('<=tablet') {
    align-self: auto;
    max-width: none;
    margin-right: $gutter/2;
    margin-left: $gutter/2;
  }
}

.club-sidebar-header {
  background-color: $indigo;
  padding: 30px;
  display: flex;
  justify-content: center;
}

.club-sidebar-body {
  padding: 20px 10px;

  .benefits-list {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  a {
    color: $grey1;
    text-decoration: underline;
    padding-left: 34px;
    display: block;
    margin-bottom: 10px;
  }
}

// ==========================================================================
// Loyalty pages
// ==========================================================================

.loyalty-main {
  flex-grow: 1;
  width: 100%;

  h3 {
    font-size: 32px;
    color: $grey1;

    @include media('<=tablet') {
      font-size: 28px;
      padding-right: $gutter/2;
      padding-left: $gutter/2;
    }
  }
}

.dashboard-points {
  display: flex;
  border-top: 2px solid $border;
  border-bottom: 2px solid $border;
  margin-top: 40px;
  margin-bottom: 20px;

  @include media('<=tablet') {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
}

.dashboard-points-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dashboard-points-item-container {
  flex: 1 0 0%;
  padding-top: 20px;
  padding-bottom: 20px;

  &:not(:last-child) {
    .dashboard-points-item {
      border-right: 2px solid $border;
    }
  }

  @include media('<=tablet') {
    flex-basis: 50%;

    &:nth-child(2n) {
      .dashboard-points-item {
        border-right: none;
      }
    }

    &:nth-child(-n + 2) {
      border-bottom: 2px solid $border;
    }
  }
}

.dashboard-stat-value {
  color: $blue;
  font-size: 26px;
}

.dashboard-stat-name {
  font-size: 14px;
  margin-top: auto;
}

.dashboard-primary-actions {
  display: flex;
  justify-content: space-between;
  margin-left: -$gutter/2;
  margin-right: -$gutter/2;
  margin-bottom: 60px;

  @include media('<=phone') {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  > * {
    flex-grow: 1;
    margin-left: $gutter/2;
    margin-right: $gutter/2;
    width: 50%;

    @include media('<=phone') {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.cta-button-container {
  max-width: colw(2);

  .btn {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.user-information {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  display: flex;
  justify-content: space-between;

  @include media('<=tablet') {
    flex-direction: column;
  }

  .cta-button-container {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.user-information-list {
  flex-grow: 1;
}

.user-information-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.5;

  @include media('>tablet') {
    flex-direction: row;

    > * {
      width: 100%;

      &:not(:first-child) {
        margin-left: $gutter/2;
      }

      &:not(:last-child) {
        margin-right: $gutter/2;
      }
    }
  }

  span {
    display: block;
  }
}

.login-information {
  border-bottom: 1px solid $border;
}

.login-item {
  border-top: 1px solid $border;
  align-items: center;

  .btn {
    height: auto;
  }

  @include media('<=tablet') {
    align-items: stretch;

    .btn {
      margin-top: 10px;
    }
  }

  .social-fb {
    display: flex;
    align-items: center;

    span {
      width: 48px;
      display: flex;
      justify-content: center;
    }

    svg {
      color: #3b5898;    // fb color
    }
  }

  .social-g {
    display: flex;
    align-items: center;

    span {
      width: 48px;
      display: flex;
      justify-content: center;
    }

    svg {
      color: #dd4a39;    // google color
    }
  }
}

.club-benefits-localnav,
.activity-filters {
  color: $grey3;
  text-align: left !important;
  font-size: 14px;

  .ln-s-list-item {
    &.active,
    &:hover {
      > a,
      .btn-link {
        color: $orange;
      }
    }
  }

  .ln-s-list {
    text-align: left;
  }

  & + .scrollable {
    .table-colored {
      margin-top: 0;
    }
  }
}

.table-colored.loyalty-table {
  border: 0;
  text-align: left;

  td,
  th {
    border-left: 0;
    border-right: 0;
  }

  td {
    height: 60px;
  }

  .value-highlight {
    color: $grey1;
    font-size: 16px;
  }

  .td-btn {
    text-align: right;
  }

  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .js-data-item {
    display: none;

    &.filter-matches {
      display: table-row;
    }
  }
}

.club-benefits-listing-img {
  @include media('>tablet') {
    width: calc(37.5% - #{$gutter});
  }
}

.club-benefits-price-block {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  color: $green;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.club-benefits-price-block-copy {
  text-transform: uppercase;
  font-size: 13px;
}

.club-benefits-price-block-price {
  font-size: 40px;
  letter-spacing: -1.5px;
  line-height: 1;
}

.club-benefits-listing-actions {
  text-align: left;
  margin-top: 15px !important;

  @include media('<=phone') {
    .btn {
      width: 100%;
    }
  }
}

.club-benefits {
  margin-top: 40px;
  margin-bottom: 120px;
  color: $indigo;

  .loyalty-benefits-container {
    margin-right: -$gutter;
    margin-left: -$gutter;
  }

  .benefit-item-title {
    font-size: 28px;
    margin: 0;
  }

  @include media('<=desktop') {
    margin-top: 20px;
    margin-bottom: 60px;

    .loyalty-benefits-container {
      margin-right: -$gutter/2;
      margin-left: -$gutter/2;
    }
  }
}

.active-benefit-overlay {
  background-color: $indigo;
  color: $white;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.empty-list-message {
  color: $grey3;
  margin-top: 20px;
  margin-bottom: 60px;
}

.loyalty-ln {
  flex-wrap: wrap;
}
