.hero {
  color: $white;
  position: relative;
  display: flex;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: 50% 50%;
  height: 410px;
  padding-top: 130px;
  max-height: 700px;

  &.no-camp {
    max-height: 440px;
  }

  @include media('>tablet') {
    height: 100vh;
  }

  .transparent-header & {
    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 33%;
      content: '';
      background: linear-gradient(rgba($black, 0.3), rgba($black, 0));
      pointer-events: none;
    }
  }
}

.hero-home {
  display: flex;
  padding-top: 60px;
  height: 100vh;
  max-height: 700px;
  min-height: 460px;
  flex-direction: column;

  @include media('<=tablet') {
    padding-top: 70px;
  }

  .container {
    margin: auto;
    position: relative;
    z-index: 1;
  }
}

.hero-meta {
  display: inline-block;
  background: $blue;
  border-radius: 5px;
  padding: 14px 24px;
  margin-top: 1em;
  font-size: 18px;
  line-height: 1;
}

.hero-copy {
  text-align: center;
  margin: 0 auto;
  max-width: colw(8);
  line-height: 1.3;
  padding: 14px 20px 20px;
  margin-top: 13px;

  * {
    color: inherit;
  }

  > * + * {
    margin-top: 12px;
  }

  @include media('<=tablet') {
    display: none;
  }
}

.hero-logo {
  width: 220px;
  height: 140px;
  margin: 0 auto 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media('<=tablet') {
    width: 170px;
    height: 90px;
    margin-bottom: 20px;

    svg {
      width: 140px;
    }
  }
}

.hero-category {
  text-transform: uppercase;
  font-size: 14px;
}

.hero-search-wrap {
  background: rgba($black, 0.3);
  padding: 0 12px 12px;
  margin-top: 1em;
}
