.tooltip-target {
  pointer-events: all;
}

.tooltip {
  @include shadow2;
  line-height: 1;
  font-size: 14px;
  position: absolute;
  z-index: 121;
  visibility: hidden;
  left: 0;
  top: 0;
  margin-left: $gutter / 2;
  margin-right: $gutter / 2;
  background: $white;
  border-radius: 3px;
  padding: 6px;
  max-width: colw(2);
  border: 1px solid $border;
  pointer-events: none;

  &.visible {
    visibility: visible;
  }
}

.tooltip-content {
  @include sr-only;
}
