.ftr {
  background: $grey5;
  margin-top: 70px;
  overflow: hidden;
}

.ftr-newsletter {
  @include spacing-m;

  h3 {
    font-weight: 400;
    font-size: 32px;
  }

  > * + * {
    margin-top: 16px;
  }
}

.ftr-sitemap {
  @include spacing-m;
  font-size: 14px;
}

.ftr-sitemap-inner {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}

.ftr-sitemap-col {
  @include pad;
  display: table-cell;
  height: 100%;

  h4 {
    margin-bottom: 20px;
  }

  @include media('<=phone') {
    display: block;

    &:first-child {
      margin-top: 0;
    }

    h4 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  > ul {
    > li {
      > a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.ftr-social {
  font-size: 14px;
  color: $grey1;

  a {
    color: inherit;
    margin-top: 12px;

    &:hover {
      color: $orange;
    }
  }
}

.ftr-legal {
  font-size: 12px;
  color: $grey3;
  border-top: 1px solid $border;
  padding-top: 1em;
  margin-bottom: 1em;

  @include media('<912px') {
    padding-right: 40 + $gutter / 2;
  }
}

.ftr-logos {
  margin-bottom: 24px;
  margin-top: 24px;

  a {
    display: inline-block !important;
    margin: 12px;
    margin-bottom: 24px;
  }
}

.back-to-top {
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  will-change: transform, opacity;

  .inner {
    margin: 0 auto;
    position: relative;
    max-width: 1400px + (40 + $gutter) * 2;
  }
}

.scroll-top {
  color: #fff;
  position: absolute;
  text-align: center;
  background: $blue;
  bottom: $gutter / 2;
  right: $gutter / 2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  will-change: transform, visibility, opacity;
  border: 1px solid #fff;
  transition: opacity 120ms ease-in-out, visibility 120ms ease-in-out;
  display: flex;
  pointer-events: all;

  .icon {
    position: relative;
    margin: auto;
    top: -2px;
  }

  html.no-touchevents &:hover {
    background: $blue-white-70;
  }

  html.touchevents &:active {
    background: $blue-white-70;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
}

.ftr-banner-container {
  padding: $gutter 0;
  text-align: center;
  background: $white;
}

.ie-chat-button-iframe-fixed-customer-chat {
  bottom: 60px !important;
  right: 12px !important;
}

.ftr-cookie-settings {
  a + a {
    margin-left: 20px;
  }
}