#ccc {
  #ccc-notify {
    flex-direction: column !important;
    padding: 20px 30px !important;
    border-top: 1px solid #0AA245;

    .ccc-notify-button {
      border-color: #0AA245 !important;
      background-color: transparent !important;
      color: #0AA245;
      
      transition: all 0.2s linear;
    }

    .ccc-button-solid {
      background-color: #0AA245 !important;
      color: #fff;

      &:hover {
        background-color: rgba(#0AA245, 0.8) !important;
        border-color: rgba(#0AA245, 0.8) !important;
      }
    }
  }
}

#ccc[light] {
  #ccc-title,
  h3 {
    color: #0AA245 !important;
  }

  #ccc-notify {
    background: #fff !important;
    background-color: #fff !important;
  }

  .ccc-notify-text h3 {
    color: #0AA245 !important;
  }

  #ccc-content {
    background-color: #fff !important;
  }
}

.ccc-notify-text {
  max-width: 800px;
  width: 100%;

  @include media('>=600px') {
    margin-bottom: 20px;
  }
}

.ccc-notify-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include media('<600px') {
    justify-content: center;
  }
}

#ccc-recommended-settings {
  border-color: #0AA245 !important;
  background-color: #0AA245 !important;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: rgba(#0AA245, 0.8) !important;
  }
}

#ccc-notify-dismiss {
  display: none !important;
}