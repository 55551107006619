.body-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
}

.body-bg-left,
.body-bg-right {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.body-bg-left {
  background: linear-gradient(to right, mix($black, $white, 15), $white);
}

.body-bg-right {
  background: linear-gradient(to left, mix($black, $white, 15), $white);
}

.body-bg-mid {
  background: $white;
  width: 100%;
  max-width: 1400px;
}

.hdr-sublist-subgroup {
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    margin-right: $gutter;
    color: $premium-text;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;

    &:hover {
      color: $orange;
    }
  }
}

.grid {
  display: flex;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;
  flex-wrap: wrap;
}

.menu-header {
  margin: 0 (-$gutter) $gutter;
  padding: $gutter / 2 $gutter;
  font-size: 18px;
  color: $blue;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.grid-item {
  margin-left: $gutter / 2;
  margin-right: $gutter / 2;

  // &.auto {
  //   flex-grow: 1;
  //   flex-shrink: 1;
  //   flex-basis: 0%;

  //   @include media('>tablet') {
  //     max-width: 50%;
  //   }
  // }
}

.grid-item-new {
  .grid-item-new-title {
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }

  .grid-item-new-copy {
    color: #51515a;
    margin-top: 0 !important;
  }
}

.grid-item-menu-col {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  margin-left: $gutter / 2;
  margin-right: $gutter / 2;
}

.location-group {
  margin-bottom: $gutter / 2;
}

.brands-title {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1em;
}

.brand-header {
  background: $black;
  display: flex;
  padding: 15px;
  margin-bottom: $gutter / 2;

  .icon {
    margin: auto;
    max-width: 100%;
  }
}

.landing-header-container {
  display: flex;
  align-content: flex-start;
  justify-content: center;
}

.logo-brands {
  max-width: colw(12);
  width: 100%;
  margin: 28px auto;
  padding: 15px;
  position: relative;
  align-self: flex-end;

  @include media('<=tablet') {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .brands-section & {
    @include media ('>tablet') {
      &::before {
        content: '';
        top: 50%;
        left: 0;
        position: absolute;
        width: 100px;
        height: 2px;
        background-color: $grey3;

        @include media('<=tablet') {
          top: 45px;
          width: 50px;
          left: 10%;
        }
      }

      &::after {
        content: '';
        top: 50%;
        right: 0;
        position: absolute;
        width: 100px;
        height: 2px;
        background-color: $grey3;

        @include media('<=tablet') {
          top: 45px;
          width: 50px;
          right: 10%;
        }
      }
    }
  }

  .hero-brands & {
    z-index: 1;

    @include media('<=tablet') {
      display: none;
    }
  }
}

.logo-brands-container {
  background-color: $white;
  border-radius: 3px;
  color: $black;
  display: flex;
  align-items: center;
  padding: 28px 0;

  @include media('<=tablet') {
    flex-direction: column;
    padding: 0;
    align-items: flex-start;

    svg {
      margin-bottom: 20px;
    }

    .hero & {
      padding: 15px;
    }
  }

  svg {
    width: 160px;
    flex-shrink: 0;
  }

  .brands-section & {
    max-width: colw(8);
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    @include media('<=tablet') {
      width: 100%;
    }
  }
}

.hero-brands-mobile {
  @include media ('>tablet') {
    display: none;
  }

  position: relative;
  top: -120px;
  margin-bottom: -120px;

  .logo-brands-container {
    padding: 15px;
  }
}

.logo-brands-logo {
  max-width: colw(4);
  padding: 0 28px;
  flex: 1;
  display: flex;
  justify-content: center;

  @include media('<=tablet') {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @include media('<=phone') {
    width: 100%;
    justify-content: flex-start;
  }
}

.logo-brands-text {
  @include media('>tablet') {
    padding-right: 28px;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 14px;
  }
}

.grid-item-brand {
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;

  @include media('<=tablet') {
    @include gridcol(1, 1);
    flex-basis: auto;
  }

  & + .grid-item-brand {
    @include media('<=tablet') {
      border-top: 1px solid rgba($black, 0.1);
    }
  }

  &.brand-premium {
    color: $premium-basic;

    .brand-header,
    &.brand-header {
      background: $premium-basic;
    }
  }

  &.brand-resort {
    color: $resort-basic;

    .brand-header,
    &.brand-header {
      background: $resort-basic;
    }
  }

  &.brand-sunny {
    color: $sunny-basic;

    .brand-header,
    &.brand-header {
      background: $sunny-basic;
    }
  }

  .blocklink {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  p {
    font-size: 14px;
    margin-bottom: 30px;
  }

  img {
    margin-top: auto;
    margin-bottom: 10px;
  }

  .location-group {
    margin-bottom: 0;
  }
}

.grid-item-location {
  position: relative;

  @include gridcol(1, 4);

  @include media('>phone', '<=tablet') {
    @include gridcol(1, 2);
    margin-bottom: $gutter;
  }

  @include media('<=phone') {
    @include gridcol(1, 1);
    margin-bottom: $gutter;
  }

  .blocklink {
    height: 100%;
  }
}

.grid-brands-homepage {
  .grid-item-brand {
    @include media('<=phone') {
      @include gridcol(1, 1);
      flex-basis: auto;
      margin-bottom: $gutter;
    }

    @include media('>phone', '<=tablet') {
      @include gridcol(1, 2);
      flex-grow: 1;
      max-width: calc(50% - #{$gutter});
      margin-bottom: $gutter;
    }
  }
}

.top-overlay-copy {
  @include divfill;
  padding: 50px 20px;
  position: absolute !important;
  color: $white;
  text-align: center;

  p {
    font-size: 14px;
    margin-top: 18px;
  }
}

.top-overlay-title {
  font-weight: 400;
  font-size: 24px;
}

.listing-logo {
  display: table;
}

.transparent-header {
  .hdr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    color: $white;
    background: transparent;
  }

  .hdr-s {
    color: $white;
    border-bottom: 1px solid rgba($white, 0.5);

    @include aa;
  }

  .hdr-p-list-item {
    color: $white;
    line-height: 1.2;

    &.is-hovered {
      .menu-item-inner {
        opacity: 0.8;
      }

      .menu-item-outer {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          bottom: -22px;
          left: 50%;
          margin-left: -8px;
          z-index: 25;
          border-style: solid;
          border-width: 0 8px 9px;
          border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $white;
        }
      }
    }
  }
}

.menu-view-bar {
  margin-bottom: $gutter / 2;
  color: $grey3;
  font-size: 14px;

  .btn-link {
    color: $premium-text;
    margin-left: $gutter / 2;

    &.active {
      text-decoration: underline;
    }
  }
}

.dropdown-display-item {
  &:not(.active) {
    display: none;
  }
}

.hero-container {
  position: relative;
  color: $white;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.hero-color-box {
  @include aa;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  will-change: opacity;
  background: rgba($green-dark, 0.9);

  // .icon {
  //   @include media('<=phone') {
  //     width: 160px;
  //     height: auto;
  //   }
  // }

  @include media('<=phone') {
    position: relative;
  }
}

.hero-brands {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background: linear-gradient(rgba($black, 0), rgba($black, 0.3));
    z-index: 0;
  }

  .hero-copy {
    position: absolute;
    bottom: 0;
    left: $gutter / 2;
    right: $gutter / 2;
    z-index: 1;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.info-rating {
  display: flex;
  margin-bottom: 5px;
  color: #fb0;
}

.js-map-wrap {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.stretch-wrap {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;

  > div {
    padding-bottom: 1em;

    .hdr-sublist-title {
      display: block;
    }
  }

  @include media('<=tablet') {
    * {
      display: block;
    }
  }

  @include media('>tablet') {
    padding-right: $gutter / 2;
    flex-direction: column;
    column-count: 2;
    column-gap: $gutter;
  }
}

.brand-intro {
  // display: flex;
  // flex-direction: column;
  padding-bottom: 80px;
  border-bottom: 5px solid #fbbc01;

  .gb-premium & {
    background: $premium-basic;
    // color: $premium-text;
  }

  .gb-sunny & {
    background: $sunny-basic;
    // color: $sunny-text;
  }

  .gb-resort & {
    background: $resort-basic;
    // color: $resort-text;
  }
}

.brand-intro-inner {
  @include media('>tablet') {
    display: flex;
    margin-top: -130px;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: colw(12);
  position: relative;
  z-index: 5;
}

.brand-intro-copy {
  flex-basis: 50%;
  padding: 80px;
  background: $white;

  @include media('<=phone') {
    @include negative-margin;
    padding: $gutter;
  }

  // .gb-premium & {
  //   background: $premium-bg;
  //   color: $premium-text;
  // }

  // .gb-sunny & {
  //   background: $sunny-bg;
  //   color: $sunny-text;
  // }

  // .gb-resort & {
  //   background: $resort-bg;
  //   color: $resort-text;
  // }

  h2 {
    font-size: 48px;
    font-weight: 300;

    @include media('<=phone') {
      font-size: 34px;
    }

    &::after {
      content: '';
      height: 2px;
      display: table;
      background: #fbbc01;
      width: 50px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.brand-intro-img {
  flex-basis: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media('>tablet') {
    min-height: 588px;
  }

  @include media('<=tablet') {
    height: 400px;
  }
}

.ttu-title {
  text-transform: uppercase;
  font-size: 24px;
  // font-weight: 300;
  margin: 2em auto;
  text-align: center;

  // .gb-premium & {
  //   color: $premium-text;
  // }

  // .gb-sunny & {
  //   color: $sunny-text;
  // }

  // .gb-resort & {
  //   color: $resort-text;
  // }
}

.characteristics-item {
  align-items: center;
  padding: 40px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $grey4;
  }

  @include media('>tablet') {
    display: flex;

    &:nth-of-type(2n - 1) {
      .characteristics-item-img {
        margin-right: 80px;
      }
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;

      .characteristics-item-img {
        margin-left: 80px;
      }
    }
  }
}

.characteristics-item-img {
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;

  @include media('<=tablet') {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 150px;
    margin-bottom: 40px;
  }
}

.characteristics-item-copy {
  // max-width: colw(5);
  width: 100%;
  margin-right: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  h3 {
    font-size: 48px;
    font-weight: 300;

    @include media('<=tablet') {
      font-size: 34px;
      text-align: center;
    }

    // .gb-premium & {
    //   color: $premium-text;
    // }

    // .gb-resort & {
    //   color: $resort-text;
    // }

    // .gb-sunny & {
    //   color: $sunny-text;
    // }

    &::after {
      content: '';
      height: 2px;
      display: table;
      background: #fbbc01;
      width: 50px;
      margin-top: 30px;
      margin-bottom: 30px;

      @include media('<=tablet') {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  p {
    color: $grey3;
    font-size: 18px;
  }
}

.new-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;

  @include media('<=tablet') {
    .new-gallery {
      margin: 0;

      .new-gallery-quarter {
        width: 50%;
      }
    }
  }
}

.new-gallery-quarter {
  flex-grow: 1;
  flex-shrink: 1;

  @include media('>tablet') {
    margin-left: $gutter / 2;
    margin-right: $gutter / 2;
    width: calc(50% - #{$gutter});

    &:not(:nth-of-type(n + 3)) {
      margin-bottom: $gutter;
    }
  }

  @include media('<=tablet') {
    width: 100%;

    img {
      width: 100%;
    }

    &:nth-of-type(3) {
      order: 5;
    }
  }
}

.new-gallery-post {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $green-dark;
  color: white;

  @include media('<=tablet') {
    // order: 20;
  }
}

.new-gallery-post-inner {
  @include aa;
  padding: $gutter;
  margin: auto;
  color: $white !important;
  display: flex;
  flex-wrap: wrap;

  .grid {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

.ngp-title {
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 1.5em;
  text-align: center;

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    color: $white;
  }
}

.ngp-image {
  @include gridcol(1, 2);

  @include media('<=phone') {
    @include gridcol(1, 1);
    margin-bottom: $gutter;
  }
}

.ngp-copy {
  @include gridcol(1, 2);

  @include media('<=phone') {
    @include gridcol(1, 1);
  }
}

.ngp-user {
  font-size: 14px;
  font-weight: bold;
  margin-top: 1em;
}

.camp-intro {
  display: flex;
  margin-top: 64px;
  margin-bottom: 64px;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;

  @include media('<=tablet') {
    flex-direction: column;
  }
}

.camp-intro-copy {
  @include gridcol(1, 1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  @include media('>tablet') {
    margin-right: percentage(1 / 8);
  }

  &:last-child {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter / 2;
    max-width: colw(8);
  }

  .rt-container {
    margin-top: 0 !important;

    // h2 {
    //   .gb-premium & {
    //     color: $premium-text;
    //   }

    //   .gb-sunny & {
    //     color: $sunny-text;
    //   }

    //   .gb-resort & {
    //     color: $resort-text;
    //   }
    //   // font-weight: 300 !important;
    // }
  }
}

.camp-intro-features {
  flex-shrink: 1;
  flex-basis: auto;
  margin-left: $gutter / 2;
  margin-right: $gutter / 2;

  @include media('>tablet') {
    width: percentage(4 / 12);
    flex-grow: 0;
  }

  @include media('<=tablet') {
    margin-top: 40px;
  }
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: $gutter;
  font-size: 14px;

  // .gb-premium & {
  //   color: $premium-text;
  // }

  // .gb-sunny & {
  //   color: $sunny-text;
  // }

  // .gb-resort & {
  //   color: $resort-text;
  // }

  .icon {
    flex-shrink: 0;
    color: $grey4;
    margin-right: $gutter;
  }
}

.feature-item-title {
  a {
    display: inline-block;
    text-decoration: underline;
    color: #000;

    &:hover {
      color: #ff6400;
    }
  }
}

.brand-main-copy {
  @include aa;

  background: $green-dark;
  color: $white;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 70px;
  padding-bottom: 330px;

  h2 {
    text-align: center !important;
    font-weight: 300 !important;
    font-size: 48px !important;
    color: $white !important;

    &::after {
      content: '';
      height: 2px;
      display: table;
      background: #fbbc01;
      width: 50px;
      margin: 30px auto;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    color: inherit !important;
    text-decoration: underline !important;
  }

  + * {
    margin-top: -350px;
  }
}

.gallery-btn-container {
  @include container;
  position: relative;

  > .btn-link {
    @include aa;
    color: $white;
    position: absolute;
    right: $gutter / 2;
    top: -40px;
    text-shadow: 0 0 50px rgba($black, 0.8);

    &:hover {
      opacity: 0.8;
    }
  }
}

.camp-intro-btns {
  display: flex;
  margin-top: auto;
  color: $green-dark;

  /* stylelint-disable-next-line no-descending-specificity */
  .btn-link {
    color: inherit;
    margin-left: $gutter;
    position: relative;

    &:hover {
      color: $orange;
    }

    &::after {
      content: '';
      height: 2px;
      position: absolute;
      bottom: -3px;
      left: 0;
      background: #fbbc01;
      width: 100%;
    }
  }
}

.accomodation-options-item {
  @include gridcol(1, 3);
  border-bottom: 5px solid #fbbc01;

  @include media('<=tablet') {
    @include gridcol(1, 1);
    margin-bottom: $gutter;
  }

  h4 {
    font-size: 36px;
    font-weight: 300;

    + div {
      font-size: 14px;
    }
  }
}

.icon-circle {
  display: inline-flex;
  // flex-direction: column;
  width: 60px;
  height: 60px;
  margin: 20px auto;
  border-radius: 50%;
  border: 1px solid $white;

  > .icon {
    margin: auto;
  }
}

.camp-news-item {
  @include gridcol(1, 2);

  @include media('<=tablet') {
    @include gridcol(1, 1);
    margin-bottom: $gutter;
  }

  > .rt-container {
    margin: 0;
  }
}

.camp-news-item-img {
  position: relative;

  > img {
    width: 100%;
  }
}

.camp-news-item-title {
  @include aa;
  padding: $gutter;
  font-weight: 400;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: $white;
  border-bottom: 5px solid #fbbc01;
  background: rgba($green-dark, 0.9);
}

.more-btn {
  .gb-premium & {
    color: $premium-text;
  }

  .gb-sunny & {
    color: $sunny-text;
  }

  .gb-resort & {
    color: $resort-text;
  }

  color: $orange;
  border: 1px solid currentColor;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px $gutter;
  display: table;
  margin-top: 30px;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
}

// .new-section-title {
// font-weight: 300;
// }

.price-badge {
  @include aa;
  color: $white;
  position: absolute !important;
  top: 0;
  right: 0;
  font-size: 14px;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  // font-weight: 300;

  /* stylelint-disable-next-line no-descending-specificity */
  > div {
    font-size: 2em;
  }

  .gb-premium & {
    background: rgba($premium-basic, 0.9);
  }

  .gb-sunny & {
    background: rgba($sunny-basic, 0.9);
  }

  .gb-resort & {
    background: rgba($resort-basic, 0.9);
  }
}

.header-stars {
  flex-wrap: nowrap;
  flex-shrink: 0;

  @include media('<=tablet') {
    display: none;
  }
}

.header-availability {
  margin: 70px 0;
  font-size: 20px;
  color: $blue;
  line-height: 1.4;

  &.unavailable {
    h3 {
      color: $red;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  h3 {
    font-size: 1em;
    line-height: 1.4;
  }

  .gb-premium & {
    color: $premium-text;
  }

  .gb-sunny & {
    color: $sunny-text;
  }

  .gb-resort & {
    color: $resort-text;
  }
}

.brand-gallery {
  position: relative;
}

.brand-gallery-images {
  display: flex;
  flex-wrap: wrap;
}

.brand-gallery-img {
  width: 100%;

  @include media('>tablet') {
    width: 50%;
  }

  &:first-of-type {
    width: 100%;
  }

  > img {
    width: 100%;
  }
}

.brand-gallery-inner {
  position: relative;
  max-width: colw(10);
  margin-left: auto;
  margin-right: auto;
}

.brand-gallery-title {
  @include aa;
  position: absolute;
  color: $white;
  padding: 40px 60px;
  font-weight: 300;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;

  strong {
    display: block;
    font-weight: 400;
  }

  .gb-premium & {
    background: rgba($premium-basic, 0.9);
  }

  .gb-sunny & {
    background: rgba($sunny-basic, 0.9);
  }

  .gb-resort & {
    background: rgba($resort-basic, 0.9);
  }
}

.brand-result-box {
  @include aa;
  color: $white;
  display: flex;
  padding: 30px;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 4px;

  @include media('<=tablet') {
    flex-direction: column;
  }

  &.brand-premium {
    background: $premium-basic;
  }

  &.brand-sunny {
    background: $sunny-basic;
  }

  &.brand-resort {
    background: $resort-basic;
  }
}

.brand-result-box-logo {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 100%;

  > .icon {
    width: 100%;
  }

  @include media('>tablet') {
    flex-shrink: 0;
    margin-right: colw(1);
  }
}

.brand-result-box-copy {
  max-width: colw(6);

  @include media('<=tablet') {
    margin-top: 30px;
  }
}

#hdr-m-sublist-support {
  .grid {
    @include media('<=tablet') {
      flex-direction: column;
    }
  }
}

.tall-img {
  @include ratio(1, 1);
  background-size: cover;
  background-position: center;

  @include media('<=tablet') {
    @include ratio(1, 1);
  }

  @include media('>tablet') {
    @include ratio(350, 600);
  }
}

.special-cell-wrap {
  position: relative;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.special-cell {
  @include ratio(1400, 440);
  width: 100%;
  background-size: cover;
  background-position: center;
  min-height: 200px;
}

.special-cell-center-copy {
  @include divfill;
  @include aa;
  color: $white;
  display: flex;
  flex-direction: column;

  @include media('<=tablet') {
    background: rgba($black, 0.3);
  }
}

.scc {
  margin: auto;
  text-align: center;
  width: 100%;
  max-width: colw(8);
  border-radius: 10px;

  @include media('>tablet') {
    background: rgba($green, 0.9);
    padding: 50px 24px;
  }

  .special_offers + * {
    margin-top: 20px;
  }
}

.grid-brands-with-locations {
  @include media('>tablet') {
    > .grid-item {
      @include gridcol(1, 3);
      flex-basis: auto;

      &:nth-of-type(1) {
        order: 1;
      }

      &:nth-of-type(2) {
        order: 4;
      }

      &:nth-of-type(3) {
        order: 2;
      }

      &:nth-of-type(4) {
        order: 5;
      }

      &:nth-of-type(5) {
        order: 3;
      }

      &:nth-of-type(6) {
        order: 6;
      }
    }
  }
}

.grid-item-loc-group {
  @include gridcol(1, 1);

  @include media('>tablet') {
    @include gridcol(1, 3);
  }
}
