.hdr {
  z-index: 30;
  position: relative; // Must have, don't remove
  box-shadow: 0 3px 6px rgba($black, 0.13);
  background: $white;
}

// Header Editing process message
// =============================

.hdr-editing-process {
  svg{
    color: $white;
    margin-bottom: 10px;
  }
  color: $black;
  background: $blue;
  padding: $gutter;
  text-align: center;

  p:first-child {
    font-size: 22px;
  }

  p:last-child {
    margin-top: 20px;
  }

  .btn {
    font-size: 12px;
    font-weight: 700;
    min-width: 200px;
  }
}

// All purpose message positioned inside the header
// =============================
.hdr-message {
  display: none;
  text-align: center;
  color: $black;
  background: $yellow;

  .container {
    position: relative;
  }

  &.is-error {
    color: $black;
    background: $yellow;
  }

  &.is-success {
    color: $black;
    background: $yellow;
  }

  &.is-visible {
    display: block;
  }
}

.hdr-message-inner {
  padding: $gutter;
  margin: 0 46px;
}

.hdr-message-close {
  position: absolute;
  color: inherit;
  right: 0;
  top: 50%;
  height: 30px;
  widows: 30px;
  margin-top: -15px;

  &:hover {
    color: $orange;
  }

  .hdr-message.is-error & {
    &:hover {
      color: $orange;
    }
  }

  .hdr-message.is-success & {
    &:hover {
      color: $orange;
    }
  }
}

// Header generic dropdown class
// =============================

.loyalty-user {
  // position: relative;
  cursor: default;
  color: $indigo;

  svg {
    color: $grey3;
  }

  &::after {
    border-color: transparent transparent $indigo-dark !important;
  }
}

.transparent-header .loyalty-user {
  color: $white;
}

.hdr-dropdown {
  z-index: 20;
  color: $black;
  pointer-events: none;
  // background: $white;
  position: absolute;
  // white-space: nowrap;
  // text-align: right;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0;
  visibility: hidden;
  // transition: opacity 140ms cubic-bezier(1, 0, 0.2, 0.5), visibility 140ms cubic-bezier(1, 0, 0.2, 0.5);

  .is-hovered & {
    opacity: 1;
    visibility: visible;
    // transition: opacity 100ms ease, visibility 100ms ease;
  }
}

.small-dropdown {
  @include shadow2;
  color: $black;
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  text-align: right;
  background: $white;

  .no-touchevents .hdr-s-list-item:hover &,
  .hdr-s-list-item.is-hovered & {
    opacity: 1;
    visibility: visible;
  }

  a {
    padding: 10px 14px;
    display: block;

    &:hover {
      color: $orange;
    }

    &.is-active {
      color: $orange;
      background: $grey5;
    }
  }

  &.user-dropdown {
    color: $white;
    background-color: $indigo-dark;
    padding: 20px;
    top: 100%;
  }
}

.user-dropdown {
  a {
    text-align: left;
    text-transform: none;
    font-weight: 300;
    padding: 8px 0;
    font-size: 14px;

    &:hover {
      color: $indigo-white-50;
    }

    &.user-logout {
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
}

.user-dropdown-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0 10px 20px;

  + ul {
    margin-top: 10px;
  }
}

.hdr-dropdown {
  top: 100%;
  left: 0;
  right: 0;
}

.hdr-login-target {
  text-transform: none;
  font-weight: 700;
}

.hdr-s,
.hdr-p {
  // display: none;                           // display:none interfered with svg defs (multiple instances of same ids)
  visibility: hidden;
  height: 0;

  @include media('>tablet') {
    visibility: visible;
    height: auto;
    // display: block;
  }
}

// Header secondary
// ================

.hdr-s {
  color: $grey3;
  border-bottom: 1px solid $grey4;
  font-size: 13px;
  line-height: 1;
  text-align: right;
  position: relative;
  z-index: 125;

  a {
    color: inherit;
  }

  @include media('<=tablet') {
    overflow-x: hidden;
    border: 0 !important;                   // remove border in all cases (needed because of visibility & height being used instead of display:none)
  }
}

.hdr-s-pull-right {
  display: flex;
  width: 100%;
  padding-top: 8px;
  // padding-bottom: 8px;
}

.hdr-s-logo {
  display: flex;
  align-items: center;
}

.hdr-s-list {
  // float: left;
  text-transform: uppercase;
  display: flex;
  margin-left: auto;
}

.hdr-s-list-item {
  position: relative;

  &:not(.has-dropdown) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.hdr-s-target {
  display: block;
  cursor: pointer;
  padding: 13px 8px 20px;
}

.hdr-s-list-item.has-dropdown.is-hovered,
.no-touchevents .hdr-s-list-item.has-dropdown:hover {
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    z-index: 25;
    border-style: solid;
    border-width: 0 8px 9px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $white;
  }
}

.hdr-s-list-item.has-dropdown {
  .hdr-s-target {
    &::after {
      display: inline-block;
      content: '';
      width: 8px;
      height: 11px;
      margin-left: 5px;
      background-color: rgba(0, 0, 0, 0);
      background-image: svg-load('arrow_drop_down.svg', fill=$white);
      background-size: 8px;
      background-position: right 5px;
      background-repeat: no-repeat;
    }
  }
}

// Header primary
// ==============

.hdr-p {
  font-weight: 500;
  font-size: 14px;
  // line-height: 1.25;
  // white-space: nowrap;
  position: relative;
  z-index: 120;

  .transparent-header & {
    font-weight: 400;
  }

  .container {
    display: flex;
    // align-items: center;
  }

  @include media('>laptop') {
    font-size: 18px;
  }
}

.hdr-p-logo {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 15px;

  @include media('<=laptop') {
    svg {
      height: 50px;
      width: auto;
    }
  }
}

.hdr-p-list {
  margin-left: auto;
  display: flex;
  overflow-x: hidden;
  padding-top: 22px;
  padding-bottom: 22px;
}

.hdr-p-list-item {
  display: flex;

  .arrow_drop_down {
    color: $grey3;
    margin-left: 8px;

    .transparent-header & {
      color: $white;
    }
  }

  &.is-hovered {
    // color: $green;

    .arrow_drop_down {
      color: inherit;
    }
  }
}

.menu-item-outer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  z-index: 21;

  @include media('>laptop') {
    padding: 0 1em;
  }
}

.menu-item-inner {
  > span {
    display: block;
  }
}

.hdr-p-support {
  color: $green;
}

// CHECKOUT header

.hdr-checkout {
  .checkout-logo {
    padding: 10px 0;

    .icon {
      height: 60px;
      width: auto;

      @include media('>phone') {
        height: 100px;
      }
    }
  }

  .hdr-m .hdr-sublist {
    display: block;
  }
}

.hdr-p-right {
  margin-left: auto;

  > * {
    height: 100%;
  }
}

.checkout-title {
  padding: 0 $gutter 0 $gutter*2;
  display: flex;
  align-items: center;

  > h1 {
    font-size: 36px;
    color: $green;
    line-height: 1;
    margin: 0;

    @include media('<=laptop') {
      font-size: 32px;
    }

    @include media('<=phone') {
      @include sr-only;
    }
  }
}

.checkout-support {
  display: table-cell;
  vertical-align: middle;
  color: $green;
  text-align: right;
  font-weight: 700;
  line-height: 1;

  @include media('>phone') {
    font-size: 20px;
  }

  > span {
    display: block;
  }

  &:hover {
    color: $orange;
  }
}

// Header only with logos
.hdr-only-logos {
  @include media('>tablet') {
    padding-top: 8px;
  }

  .hdr-s-logo {
    margin-bottom: 8px;
  }
}

// Header notifications bar
.hdr-notification {
  display: none;
  background-color: #0AA245;
  padding-top: 8px;
  padding-bottom: 8px;

  &.isActive {
    display: block;
  }

  a {
    color: #fff;
  }

  .container {
    position: relative;
  }
}

.hdr-notification-inner {
  text-align: center;
  padding-right: 30px;

  ul,
  ol {
    margin-top: 10px;
    padding-left: 20px;
  }
}

.hdr-notification-close {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 10px;
  line-height: 1;
}