// ==========================================================================
// Mixins go here
// ==========================================================================

@mixin cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin fix-overflow {
  &::after,
  &::before {
    content: '';
    display: table;
    clear: both;
  }
}

@function colw($n) {
  @return $n * $col + $n * $gutter;
}

/* Columns mixin */
@mixin gridcol($columns, $context: $ncol, $margin: $gutter) {
  $width: 100% / $context * $columns;

  @if $margin == auto {
    margin-right: auto;
    margin-left: auto;
  } @else {
    margin-right: $gutter / 2;
    margin-left: $gutter / 2;
  }
  width: calc(#{$width} - #{$margin});
}

@mixin negative-margin {
  margin-left: - ($gutter / 2);
  margin-right: - ($gutter / 2);
}

@mixin pad {
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin container {
  // @include cf;
  @include pad;
  @include center-block;
  max-width: colw(12);
}

@mixin row {
  @include cf;
  @include negative-margin;
}

@mixin col($n: 0) {
  @include pad;
  float: left;
  min-height: 1px;

  @if ($n != 0) {
    width: percentage($n / 12);
  }
}

@mixin divfill($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin sr-only {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
}

@mixin img-fluid {
  width: 100%;
  display: block;
}

@mixin img-rounded {
  border-radius: 50%;
}

@mixin overlay {
  @include divfill;
  content: '';
  pointer-events: none;
  z-index: 0;
}

@mixin aa {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin no-aa {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: subpixel-antialiased;
}

@mixin focus-outline {
  outline: -webkit-focus-ring-color auto 5px;
}

@mixin spacing-l {
  margin-top: 60px;
  margin-bottom: 60px;
}

@mixin spacing-m {
  margin-top: $spacing_size_m;
  margin-bottom: $spacing_size_m;
}

@mixin spacing-s {
  margin-top: $spacing_size_s;
  margin-bottom: $spacing_size_s;
}

@mixin valign-center {
  white-space: nowrap;
  line-height: 1;

  > * {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin ratio($x, $y) {
  padding-bottom: percentage($y / $x);
  height: 0;
}

@mixin span($x1, $x2) {
  width: percentage($x1 / $x2);
}

// SHADOWS

@mixin shadow1 {
  box-shadow: 0 1px 3px rgba($black, 0.12), 0 1px 2px rgba($black, 0.24);
}

@mixin shadow2 {
  box-shadow: 0 3px 6px rgba($black, 0.16), 0 3px 6px rgba($black, 0.23);
}

@mixin shadow3 {
  box-shadow: 0 10px 20px rgba($black, 0.19), 0 6px 6px rgba($black, 0.23);
}

@mixin shadow4 {
  box-shadow: 0 14px 28px rgba($black, 0.25), 0 10px 10px rgba($black, 0.22);
}

@mixin shadow5 {
  box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.22);
}
