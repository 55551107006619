.nm-container {
  display: flex;
}

.nm-list {
  color: $grey3;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  @include media('>tablet') {
    max-width: colw(3);
  }
}

.nm-map {
  background: #d5d5d5;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;

  @include media('>tablet') {
    margin-left: $gutter;
    min-height: 500px;
  }

  @include media('<=tablet') {
    display: none;
  }
}

.nm-list-control {
  width: 100%;
  cursor: pointer;

  &:hover,
  &.expanded {
    color: $blue;
  }

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  h4 {
    font-weight: 400;
  }

  .caret_up {
    display: none;
  }

  &.expanded {
    .caret_up {
      display: block;
    }

    .caret_down {
      display: none;
    }
  }

  .icon {
    margin-right: 10px;
  }
}

.nm-tablist {
  display: flex;
  border-bottom: 1px solid $blue;
}

.nm-tab-control {
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  height: 50px;
  color: $blue;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  width: 50%;

  &.active {
    @include aa;
    background: $blue;
    color: $white;
  }
}

.nm-label {
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    color: $blue;
  }
}

.nm-radio-visual {
  border: 1px solid $blue;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 12px;
  display: flex;
  flex-shrink: 0;
}

.nm-item-title {
  display: flex;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: auto;

  .icon {
    margin-right: 12px;
  }

  span + .icon {
    margin-left: 8px;
    margin-right: 0;
  }
}

.nm-radio {
  @include sr-only;

  &:checked {
    ~ .nm-item-title {
      color: $blue;
    }

    ~ .nm-radio-visual {
      &::before {
        content: '';
        background: $blue;
        width: 14px;
        height: 14px;
        margin: auto;
        border-radius: 50%;
      }
    }
  }
}

.nm-acc-label {
  @include aa;
  border-radius: 50%;
  background: $blue;
  color: $white;
  font-size: 10px;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

.nm-list-cat {
  overflow: hidden;
  border-bottom: 1px solid #d5d5d5;
}

.nm-list-content {
  padding-bottom: 16px;
  overflow: hidden;

  &:not(.expanded) {
    display: none;
  }
}

.nm-tab-container {
  position: relative;
}

.nm-tab {
  &:not(.active) {
    display: none;
  }
}
