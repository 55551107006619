// ==========================================================================
// Mobile header / navbar
// ==========================================================================

.hdr-m {
  padding: ($gutter / 2) ($gutter / 2) 0;
  position: relative;
  z-index: 101;
  // display: flex;

  @include media('>tablet') {
    display: none;
  }

  &:last-child {
    padding: ($gutter / 2);
  }
}

.hdr-m-logo-top {
  background-color: $white;
  padding: 8px;
  border-bottom: 1px solid $grey4;
  display: flex;
  justify-content: center;

  @include media('>tablet') {
    display: none;
  }
}

.hdr-m-list {
  // text-align: right;
  // font-size: 0;
  // margin-left: auto;
  display: flex;
  align-items: center;
}

.hdr-m-list-item {
  position: relative;
  // display: inline-block;
  // vertical-align: top;
  font-size: 12px;
  // height: 39px;

  &:first-child {
    margin-right: auto;
  }
}

.hdr-m-select {
  height: 39px;
  // background-color: $white;
  background-color: transparent;
  background-image: svg-load('arrow_drop_down.svg', fill=$white);
  background-size: 8px;
  background-position: right 9px center;
  background-repeat: no-repeat;
  padding-right: 20px;
  padding-left: 10px;
}

.hdr-m-cheeseburger {
  // display: inline-block;
  // vertical-align: top;
  position: relative;
  height: 39px;
  // color: $orange;
  margin-left: 10px;
  margin-right: - ($gutter / 4);
  cursor: pointer;

  &:active {
    color: $blue;
  }

  .icon {
    vertical-align: top;
  }
}

.hdr-m-menu,
.hdr-m-user-profile-menu {
  color: $blue;
  font-size: 15px;
  text-align: left;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  position: fixed;
  overflow: hidden;
  // -webkit-overflow-scrolling: touch; // iOS juddering fix
}

.hdr-m-user-profile-menu {
  .hdr-m-menu-head-item:last-child {
    left: 0;
  }
}

.hdr-m-profile-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 14px;
  line-height: 15px;
}

.transparent-header .hdr-m-profile-letter {
  background-color: white;
  color: black;
}

.hdr-m-profile-icon {
  svg path {
    stroke: black;
  }
}

.transparent-header .hdr-m-profile-icon {
  svg path {
    stroke: white;
  }
}

.hdr-m-menu-head {
  height: 63px;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);

  @include cf;
}

.hdr-m-menu-head-item {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: ($gutter / 2);
  transition: transform 200ms ease-out;

  &:first-child {
    left: 0;
    text-align: right;

    .toggle {
      margin-right: -10px;
    }
  }

  &:last-child {
    left: 100%;
  }

  .toggle {
    display: inline-block;
    vertical-align: top;
    position: relative;
    line-height: 39px;
    height: 39px;
    color: $blue;
    cursor: pointer;

    &:active {
      color: $orange;
    }

    .icon {
      vertical-align: middle;
      margin-top: -4px;
    }

    &.hdr-m-menu-back {
      .icon {
        margin-right: 4px;
      }
    }
  }
}

.hdr-m-menu-inner {
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  > ul {
    transition: transform 200ms ease-out;
  }
}

.hdr-m-menu-block {
  position: absolute;
  top: 0;
  left: 100%;
  right: -100%;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &:first-child {
    left: 0;
    right: 0;
  }

  li:first-child {
    .hdr-m-menu-title {
      border-top: 0;
    }
  }
}

.hdr-m-menu-title {
  color: $blue;
  display: block;
  cursor: pointer;
  position: relative;
  white-space: normal;
  padding: 10px ($gutter / 2);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 24px;

  &:hover {
    color: inherit;
  }

  &:active {
    color: $orange;
  }

  &.is-strong {
    font-weight: 700;
  }

  &.is-plus-club {
    font-weight: 400;

    &::before {
      content: svg-load('material/account_circle.svg', fill=$blue);
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      line-height: 24px;
      height: 24px;
    }
  }

  &.has-sublist {
    padding-right: 40px;

    &::after {
      content: svg-load('caret_right.svg', fill=$blue);
      position: absolute;
      display: inline-block;
      margin-top: -12px;
      right: $gutter / 2;
      height: 24px;
      top: 50%;
      transition: transform 60ms ease-out;
    }

    &:active {
      &::after {
        transform: translateX(4px);
      }
    }
  }
}

// Menu open
// =========
.hdr-m-menu-open,
.hdr-m-user-profile-open {
  position: relative;
  overflow: hidden;
  height: 100%;

  body {
    height: 100%;
    overflow: hidden;
  }
}

.hdr-m-menu-open .hdr-m-menu,
.hdr-m-user-profile-open .hdr-m-user-profile-menu {
  display: block;
}

// Submenu open
// ============
.hdr-m-sublist-open {
  .hdr-m-menu-inner {
    > ul {
      transform: translateX(-100%);
    }
  }

  .hdr-m-menu-head-item {
    transform: translateX(-100%);
  }
}
