// ==========================================================================
// Form layout and titles
// ==========================================================================

.form-h1 {
  color: $green;
  margin-bottom: 0.5em;
  line-height: 1.2;
  font-size: 48px;

  &:first-child {
    margin-top: 0;
  }

  > span {
    display: block;
    font-weight: 400;
    font-size: 0.5em;
  }
}

.form-h2 {
  color: $green;
  margin-top: 3em;
  margin-bottom: 1em;
  line-height: 1.2;
  font-size: 36px;

  &:first-child {
    margin-top: 0;
  }

  + .form-h3 {
    margin-top: 0.875em;
    border-top: 1px solid $border;
    padding-top: 0.5em;
  }
}

.form-h3 {
  color: $green;
  margin-top: 1.875em;
  margin-bottom: 1em;
  line-height: 1.2;
  font-size: 24px;

  &:first-child {
    margin-top: 0;
  }
}

.aside-title {
  color: $green;
}

.form-label {
  margin-bottom: 8px;
  color: $grey1;

  > a,
  > button {
    pointer-events: all;
  }
}

// Form elements

.form-group {
  margin-bottom: 24px;

  .auto-row & {
    margin-bottom: 0;
    padding-bottom: 24px;
  }
}

.btn-row {
  margin: 24px -6px;
  line-height: 1;

  &:last-child {
    margin-bottom: 0;
  }

  > .btn {
    margin: 0 6px;
  }

  @include media('<=phone') {
    margin-right: 0;
    margin-left: 0;

    > .btn {
      display: block;
      width: 100%;
      margin: 0;

      + .btn {
        margin-top: 8px;
      }
    }
  }
}

.is-required {
  > .form-label {
    &::after {
      content: '*';
      display: inline-block;
      color: $red;
      margin-left: 0.2em;
    }
  }
}

.has-error {
  color: $red;

  input,
  select,
  textarea {
    border-color: $red;
  }
}

.has-success {
  color: $green;

  input,
  select,
  textarea {
    border-color: $green;
  }
}

.form-message {
  margin-top: 8px;
  line-height: 1.3;
  font-size: 14px;

  .media-middle & {
    margin-top: 0;
  }
}

.form-banner {
  @include spacing-m;
  display: block;
  border-radius: 3px;
  color: $white;

  &.success {
    background: $green;
  }

  &.error {
    background: $red;
  }
}

.clearable-wrap {
  position: relative;

  input::-ms-clear {
    display: none;
  }
}

.clear-input {
  border: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 46px !important;
  height: 46px;
  z-index: 2;
  background: rgba(0, 0, 0, 0);
  display: none;
  pointer-events: all;
  cursor: pointer;

  * {
    pointer-events: all !important;
  }

  &.is-visible {
    display: block;
  }

  &:hover {
    color: $orange !important;
  }
}

.secondary-info {
  font-size: 14px;
  text-align: right;

  @include spacing-s;
}

// Field placeholder
.plhl-cont {
  position: relative;
}

.plhl {
  position: absolute;
  display: none;
  z-index: 1;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  cursor: text;
  border-radius: 3px;
  text-align: left;
  padding: 0 !important;
  line-height: 0 !important;

  &::after,
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
  }

  &::before {
    height: 100%;
  }

  &::after {
    color: #646464;
    opacity: 0.6;
    padding: 6px 12px;
    content: attr(data-text);
  }
}

html.no-placeholder {
  .plhl {
    display: block;
  }

  .has-plhl:focus ~ .plhl,
  .has-plhl.has-content ~ .plhl {
    display: none;
  }
}


.recaptcha-wrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}