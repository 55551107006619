// ==========================================================================
// Styles for images
// ==========================================================================

.img-overlay {
  position: relative;
  display: block;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 1;
  }

  > img {
    @include img-fluid;
    position: static;
  }

  &.gradient-bottom {
    &::before {
      @include overlay;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba($black, 0.3));
    }
  }

  &.gradient-top {
    &::before {
      @include overlay;
      z-index: 0;
      background: linear-gradient(rgba($black, 0.3), rgba(0, 0, 0, 0));
    }
  }
}

.img-overlay-copy {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: $white;
  text-align: center;
  font-size: 24px;
  padding: $gutter / 2;

  &.copy-top {
    top: 0;
  }

  * {
    color: inherit;
  }
}

.img-fluid {
  @include img-fluid;
}

.img-rounded {
  @include img-rounded;
}

.mosaic {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.mosaic-col {
  display: table-cell;
  height: 500px;
  background-size: cover;
  background-position: 50% 50%;

  @include media('<=phone') {
    height: 180px;

    &:nth-of-type(n + 3) {
      display: none;
    }
  }

  @include media('<=tablet') {
    &:nth-of-type(n + 4) {
      display: none;
    }
  }
}

.gallery-grid {
  @include spacing-m;
  @include negative-margin;

  @include media('<=tablet') {
    .auto-col {
      &:nth-of-type(n + 4) {
        display: none;
      }
    }
  }

  @include media('<=phone') {
    .auto-col {
      display: block;
    }

    .auto-row + .auto-row {
      .auto-col {
        display: none;
      }
    }
  }
}

.grid-figure {
  margin-bottom: $gutter;

  > img {
    @include img-fluid;
  }

  > figcaption {
    font-size: 14px;
    color: $grey3;
    margin-top: 0.5em;
  }
}
