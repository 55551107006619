// ==========================================================================
// Styles for modal windows and overlays
// ==========================================================================

.noscroll, .noscroll body{
  // overflow-y: scroll;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  //height: 100%;
}

.modal-overlay {
  @include divfill(fixed);
  z-index: 100;
  overflow: hidden;
  will-change: opacity, visibility;
  transition: opacity 300ms ease-in, visibility 300ms ease-in;
  display: none;
  opacity: 0;
  visibility: hidden;
  contain: content;

  &::before {
    @include divfill(fixed);
    content: '';
    background: $black;
    opacity: 0.7;
    z-index: 0;
  }

  &.modal-rendered {
    display: block;
  }

  &.modal-visible {
    opacity: 1;
    visibility: visible;
  }

  &.modal-interactable {
    .modal-window,
    .modal-window-fs {
      pointer-events: all;
    }
  }
}

.modal-overlay-dark {
  &::before {
    opacity: 0.9;
  }

  .modal-close {
    color: $white;
  }
}

.modal-scroll-wrap {
  @include divfill;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include media('>phone') {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.modal-window {
  @include center-block;
  @include shadow2;
  position: relative;
  background: $white;
  z-index: 100;
  pointer-events: none;
  min-height: 300px;

  &.max-8 {
    max-width: colw(8) + $gutter + 8px;
  }
}

.modal-window-fs {
  @include divfill;
  background: none;
  pointer-events: none;
}

.modal-content-fs {
  @include divfill;
}

.modal-content {
  padding: 0 $gutter $gutter;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.modal-header {
  position: relative;
  height: 50px;
}

.modal-close {
  color: $black;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  line-height: 1;
  cursor: pointer;
  z-index: 2;

  &:hover {
    color: $orange;
  }
}

.login-modal-temp {
  width: colw(4);

  .modal-header {
    background-image: svg-load('valamar_rewards.svg');
    background-color: $indigo-dark;
    background-position: center;
    background-size: 150px;
    height: 84px;
  }

  .modal-close {
    color: $white;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .modal-content {
    padding: 0;
  }
}

.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    margin-top: 50px;
  }
}
