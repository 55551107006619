// ==========================================================================
// Media / flag items
// ==========================================================================

.media {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.media-body,
.media-body-top-border {
  width: 10000px;
}

.media-body-top-border {
  padding-top: 7px;
  border-top: 1px solid $grey4;
}

.media-left,
.media-right,
.media-body,
.media-body-top-border {
  display: table-cell;
  vertical-align: top;

  &.br-p {
    @include media('<=phone') {
      display: block;
      width: 100%;
    }
  }
}

.media-middle {
  vertical-align: middle;

  > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.media-bottom {
  vertical-align: bottom;
}

.media-right {
  padding-left: $gutter;

  &.align-text-right {
    text-align: right;
  }
}

.media-right-currency {
  span {
    font-size: 14px;
  }
}

.media-left {
  padding-right: $gutter;

  @include media('<=phone') {
    > img {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }
}

.media-left,
.media-right {
  img {
    display: block;
    width: auto !important;
    max-width: none !important;
  }
}
