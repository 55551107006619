.open-chat-button {
  position: fixed;
  z-index: 99111112345;
  font-weight: bold;
  bottom: 65px;
  right: 20px;
  padding: 5px;
  display: flex;
  background-color: rgb(17, 138, 203);
  border-radius: 30px;
  height: 52px;
  transition-duration: .15s;
  width: 52px;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  cursor:pointer;

  &.minimize {
    width: 52px;
    bottom: 10px;
    right: 20px;
  }

  svg#chatButton {
    display: block;
    fill: #fff;
    margin: 0 5px;
  }

  svg#chatMinimize {
    display: none;
    fill: #fff;
    margin: 0 5px;
  }
}

.open-chat-button.minimize svg#chatButton,
.open-chat-button.minimize span,
.open-chat-button span {
  display: none;
}

.open-chat-button.minimize svg#chatMinimize {
  display: block;
}

.chat-iframe-wrap {
  @include media('>=tablet') {
    max-height: 100vh !important;
    right: 20px !important;
    left: auto !important;
  }

  .iframe-shadow {
    @include media('<tablet') {
      border: 0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000;
      height: calc(100vh - 80px);
      width: calc(100vw);
      box-sizing: border-box;
    }
  }
}

body.open-chat {
  @include media('<tablet') {
    overflow: hidden;
    touch-action: none;
    height: 100vh;
  }
}