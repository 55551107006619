// ==========================================================================
// All carousels go here
// ==========================================================================

@mixin fadeimg {
  transition: opacity 0.4s ease-in;
  opacity: 0;

  &.flickity-lazyloaded,
  &.flickity-lazyerror {
    opacity: 1;
  }
}

.carousel-gallery {
  position: relative;

  @include ratio(768, 400);

  > .flickity-viewport {
    @include divfill;
  }
}

.carousel-gallery-fs {
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 0;
  right: 0;
}

.carousel-gallery-cell {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;

  .carousel-gallery-fs & {
    @include pad;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.carousel-gallery:not(.flickity-enabled) {
  .carousel-gallery-cell {
    position: absolute;
    top: 0;
    left: 0;

    img {
      opacity: 1;
    }
  }

  .carousel-gallery-cell + .carousel-gallery-cell {
    display: none;
  }
}

.carousel-gallery-img {
  @include fadeimg;
  min-width: 150px;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.carousel-pull-down {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15%;
}

.carousel-gallery-nav-fs {
  height: 100%;
  max-height: 70px;
  padding-top: $gutter / 2;
  box-sizing: content-box;
}

.carousel-gallery-nav {
  height: 70px;
  margin-top: $gutter / 2;
}

.carousel-nav-cell {
  padding: 0;
  height: 100%;
  width: 100px + 16px;
  opacity: 0.7;

  &.is-nav-selected {
    opacity: 1;
  }

  > img {
    display: block;
    max-width: initial;
  }
}

.carousel-nav-img {
  @include fadeimg;
  display: block;
  min-width: 100px;
}

.card-carousel-gallery,
.card-carousel-gallery-mobile {
  position: absolute;

  // @include ratio(1400, 440);
  @include divfill;

  // @include media('<=tablet') {
  //   @include ratio(800, 440);
  // }

  .static-accomodation & {
    @include ratio(960, 500);
  }

  > .flickity-viewport {
    @include divfill;
  }
}

.card-carousel-gallery {
  @include media('>tablet') {
    z-index: 2;
  }

  &:after {
    content: 'flickity';
    display: none; /* hide :after */
    @include media('<=tablet') {
      content: '';
    }
  }
}

.card-carousel-gallery-mobile {
  @include media('<=tablet') {
    z-index: 2;
  }
  
  &:after {
    content: 'flickity';
    display: none; /* hide :after */
    @include media('>tablet') {
      content: '';
    }
  }
}

.card-carousel-cell {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.card-carousel-gallery:not(.flickity-enabled),
.card-carousel-gallery-mobile:not(.flickity-enabled) {
  .card-carousel-cell {
    position: absolute;
    left: 0;
    top: 0;
  }

  .card-carousel-cell + .card-carousel-cell {
    display: none;
  }
}
