// ==========================================================================
// Tab lists and tab pages
// ==========================================================================

.tab-nav {
  @include media('<=tablet') {
    @include spacing-m;
  }
}

.tab-list {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
  margin-bottom: 30px;
}

.tab-item {
  display: table-cell;
  text-align: center;
  border: 1px solid $border;
  font-weight: 700;

  > a {
    line-height: 1;
    display: block;
    padding: 1em 6px;
    color: inherit;

    > span {
      position: relative;
      z-index: 2;
    }

    &.tab-open {
      color: $blue;
      position: relative;

      &::after {
        @include divfill;
        content: '';
        background: $white !important;
        z-index: 1;
      }

      &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background: $white;
        box-shadow: 0 0 0 1px $border;
        bottom: 0;
        left: 50%;
        transform: translateY(50%) translateX(-50%) rotate(45deg);
        z-index: 0;
      }
    }

    &:hover {
      background: mix($white, $grey5, 50%);
    }
  }
}

.tab-page {
  &:not(.tab-open) {
    display: none;
  }
}
