// ==========================================================================
// For use in Django forms
// ==========================================================================

form {
  button,
  input,
  select,
  textarea {
    display: block;
    height: 46px;
    border-radius: 3px;
    border: 1px solid $border;
    padding: 6px 12px;
    position: relative;
    color: $grey2;

    &:focus {
      z-index: 1;
    }
  }

  input,
  select,
  textarea {
    width: 100%;
  }

  label {
    display: inline-block;
  }

  textarea {
    height: auto;
    padding: 12px 16px;
  }

  %select {
    background-color: $white;
    background-image: svg-load('arrow_drop_down.svg', fill=$grey3);
    background-position: right 8px center;
    background-repeat: no-repeat;
    padding-right: 25px;
  }

  select {
    @extend %select;
  }
}

input:read-only {
  background: $grey5;
}
