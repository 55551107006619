// ==========================================================================
// Generic styles for local navigations
// ==========================================================================

.localnav {
  text-align: center;
}

.localnav-primary {
  font-size: 16px;
  line-height: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @include media('<=tablet') {
    font-size: 16px;
  }
}

.ln-p-list {
  display: flex;
  flex-basis: auto;
  justify-content: center;
}

.ln-p-list-item {
  position: relative;
  display: flex;

  @include media('<=tablet') {
    padding: 0 6px;
  }

  @include media('<=phone') {
    font-size: 14px;
  }

  &:not(:last-of-type) {
    margin-right: 1.2em;
  }

  > a,
  .btn-link {
    color: inherit;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.localnav-secondary {
  line-height: 1;
  position: relative;
  z-index: 2;
  font-size: 14px;
  border-bottom: 1px solid $grey4;
}

.ln-s-list {
  padding: 1em 0;
  text-align: center;
}

.ln-s-list-item {
  display: inline-block;
  border-radius: 3px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  > a,
  .btn-link {
    color: inherit;
    padding: 4px 6px;
    display: block;
  }
}

// ==========================================================================
// Green navigation (used on camp landing pages)
// ==========================================================================

.localnav-filled {
  .ln-p-list {
    // white-space: nowrap;

    @include media('<=tablet') {
      max-width: colw(5);
      margin: 0 auto;
    }
  }

  .localnav-primary {
    @include aa;
    background: $green;
    color: $white;
    font-weight: 700;

    @include media('<=tablet') {
      .ln-p-list-item {
        margin-right: 0;
        width: 25%;

        > a {
          white-space: normal;
          word-spacing: 300px;
        }
      }
    }
  }

  .ln-p-list-item {
    &:hover {
      a {
        opacity: 0.7;
      }
    }

    &.active {
      // &:not(.no-submenu) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        left: 50%;
        margin-left: -8px;
        border-style: solid;
        border-width: 0 8px 9px;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $white;
      }
    }
    // }
  }

  .localnav-secondary {
    background: $white;
    border-bottom: 1px solid $border;
    color: $grey3;
    padding: 1em 0;
  }

  .ln-s-list-item {
    margin-right: 0;

    &:hover,
    &.active {
      background: $green-dark;
      color: $white;
    }

    > a {
      border-radius: 3px;
    }
  }
}

// ==========================================================================
// Clear nav (user and destination pages)
// ==========================================================================

.localnav-clear {
  color: $grey3;

  @include media('<=tablet') {
    border-bottom: 1px solid $border;
  }

  .localnav-secondary {
    border-bottom: 1px solid $border;
  }

  .localnav-primary {
    white-space: normal;
  }

  .ln-p-list-item {
    @include media('<=tablet') {
      // display: table;
      margin-right: 0;
      font-weight: 700;
      color: $grey1;
    }

    &.active {
      color: $grey1;
      box-shadow: inset 0 -3px 0 0 $orange;
    }

    &:hover {
      color: $orange;
    }
  }

  .ln-s-list-item {
    &.active,
    &:hover {
      > a,
      .btn-link {
        color: $orange;
      }
    }
  }
}
