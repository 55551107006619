// ==========================================================================
// Opininated defaults loaded after a reset.
// ==========================================================================

html {
  position: relative;
  min-width: 320px;
  background-color: $white;
  color: $black;
  line-height: 1.5;
  // overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig', 'pnum', 'ss01', 'kern';
  text-rendering: optimizeLegibility;
  line-height: 1.5;
  position: relative;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/assets/fonts/roboto-bold.woff') format('woff'),
       url('/static/assets/fonts/roboto-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/assets/fonts/roboto-light.woff') format('woff'),
       url('/static/assets/fonts/roboto-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/assets/fonts/roboto-medium.woff') format('woff'),
       url('/static/assets/fonts/roboto-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/assets/fonts/roboto-mediumitalic.woff') format('woff'),
       url('/static/assets/fonts/roboto-mediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/static/assets/fonts/roboto-regular.woff') format('woff'),
       url('/static/assets/fonts/roboto-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

img,
video {
  max-width: 100%;
}

a {
  position: relative;
  text-decoration: none;
  // display: inline-block;
  cursor: pointer;

  &:not([class]) {
    color: $orange;
  }
}

span {
  display: inline-block;
}

span,
strong {
  text-decoration: inherit;
}

hr {
  @include spacing-m;
  display: block;
  height: 1px;
  background: $border;
  border: 0;
}

option {
  &:disabled {
    display: none;
  }
}

input,
select,
optgroup,
textarea {
  background: $white;
}

button,
[role='button'],
label,
select {
  user-select: none;
  cursor: pointer;
}

option[value='-1'] {
  display: none;
}

::placeholder {
  color: $grey3;
  font-weight: 400;
  font-style: initial;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: inherit;
  text-rendering: optimizeLegibility;
}
