// ==========================================================================
// Call to actions? Bit vague.
// ==========================================================================

.split-dtn {
  @include cf;
  height: 400px;
  position: relative;
  margin: $gutter auto;
}

.split-dtn-cell {
  float: left;
  width: percentage(1 / 2);
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.split-dtn-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -(248px / 2);
  margin-top: -(248px / 2);
  border-radius: 50%;
}

.loyalty-banner {
  @include aa;
  background: $blue;
  padding: 80px 0;

  @include media('<=phone') {
    text-align: center;
    padding: 40px 0;
  }

  * {
    color: $white;
  }

  .icon {
    @include media('<=phone') {
      @include spacing-m;
    }
  }

  .h3 {
    font-weight: 400;
    margin-bottom: 1em;

    > a {
      color: $white !important;
    }
  }

  a:not(.btn) {
    color: $orange;

    &:hover {
      color: $white;
    }
  }
}

.loyalty-checkout {
  @include spacing-m;
  @include center-block;
  max-width: colw(8);
  text-align: center;
}

.loyalty-head {
  h2 {
    margin-bottom: 30px;

    @include media('<=tablet') {
      font-size: 24px;
    }
  }

  .media {
    margin-bottom: 30px;
  }

  .auto-col {
    * + * {
      margin-top: 8px;
    }

    @include media('>tablet') {
      &:not(:first-of-type) {
        border-left: 1px solid $blue-white-30;
      }
    }

    @include media('<=tablet') {
      text-align: center !important;

      &:not(:first-of-type) {
        border-top: 1px solid $blue-white-30;
        padding-top: 16px;
        margin-top: 16px;
      }
    }
  }
}

.loyalty-title-light {
  font-weight: 400;
  color: $blue-white-30;
  margin-top: 1em;
  margin-bottom: 1em;

  > span {
    color: $white;
  }
}

.usp-why-us {
  text-align: center;
  color: $blue;

  * {
    color: inherit;
  }
}

.usp-why-us-item {
  @include pad;
  font-size: 14px;
  font-weight: 400;

  > div {
    display: table;
    margin: 0 auto;

    &:hover {
      * {
        color: $orange !important;
      }
    }
  }

  @include media('<=phone') {
    display: block;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 16px;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.title-decorated {
  position: relative;
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  margin-bottom: 1em;

  @include media('>phone') {
    font-size: 32px;
  }

  // > * {
  //   @include pad;
  //   background: $white;
  //   position: relative;
  //   display: inline-block;
  // }

  a {
    &:hover {
      color: $orange;
    }
  }

  // &::before {
  //   content: '';
  //   height: 1px;
  //   background: currentColor;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 50%;
  // }
}

.new-title {
  background: $blue !important;
  color: $white !important;
  font-weight: 400 !important;
  margin: 0 !important;
  font-size: 16px !important;
  padding: 12px !important;
}
