.cmd-container {
  @include media('>tablet') {
    display: flex;
    flex-direction: row-reverse;

    .cmd-map {
      @include gridcol(7);
    }

    .cmd-directions {
      @include gridcol(5);
    }
  }
}

.cmd-map {
  height: 450px;
}
