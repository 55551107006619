// ==========================================================================
// Entry file
// ==========================================================================

@import 'base/partials';

// Components
@import "components/_awards.scss";
@import "components/_banner.scss";
@import "components/_buttons.scss";
@import "components/_campings-map.scss";
@import "components/_cards.scss";
@import "components/_carousels.scss";
@import "components/_checkout.scss";
@import "components/_civic_cookie_control.scss";
@import "components/_cta.scss";
@import "components/_datepicker.scss";
@import "components/_expandable.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header-mobile.scss";
@import "components/_header-sublist.scss";
@import "components/_header.scss";
@import "components/_hero.scss";
@import "components/_images.scss";
@import "components/_innature-infos-grid.scss";
@import "components/_listings.scss";
@import "components/_lists.scss";
@import "components/_loyalty.scss";
@import "components/_map-directions.scss";
@import "components/_media-item.scss";
@import "components/_modal.scss";
@import "components/_navigation.scss";
@import "components/_new-map.scss";
@import "components/_news-container.scss";
@import "components/_patronChat.scss";
@import "components/_rich-text.scss";
@import "components/_searchbar.scss";
@import "components/_tables.scss";
@import "components/_tabs.scss";
@import "components/_tooltips.scss";
@import "components/_typography.scss";
@import "components/_user-dashboard.scss";
@import "components/_z_redesign.scss";

.full-container {
  position: relative;
}

.landing-header {
  text-align: center;
  padding-top: 30px;

  > .container {
    position: relative;
  }

  h1,
  .h1 {
    @include media('<=tablet') {
      font-size: 26px;
      font-weight: 400;
    }
  }
}

.section-title {
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 400;
  text-align: center;

  // .gb-premium & {
  //   color: $premium-text;
  // }

  // .gb-sunny & {
  //   color: $sunny-text;
  // }

  // .gb-resort & {
  //   color: $resort-text;
  // }

  + p {
    margin-top: -40px;
  }
}

p + .section-title {
  margin-top: -16px;
}

.header-box {
  @include spacing-l;
  background: $white;
  // padding: 30px;
  // border: 1px solid $border;

  h1 {
    font-size: 24px;
  }
}

.header-box-col {
  h3 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  @include media('<=tablet') {
    display: block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 16px !important;
    }

    > img {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}

.date-range {
  color: $green;
  font-weight: 700;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-bottom: 0.3em;
  }
}

.disclaimer {
  color: $grey3;
  font-size: 14px;
  margin-top: 1em;
  margin-bottom: 1em;

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
}

.simple-tooltip {
  @include shadow2;
  position: absolute;
  font-size: 14px;
  z-index: 20;
  min-width: 220px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 20px;
  visibility: hidden;
  line-height: 1.2;
  opacity: 0;
  will-change: visibility, opacity;
  transition: visibility 180ms ease, opacity 180ms ease;

  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    height: 20px;
    left: 0;
    right: 0;
  }

  &::after {
    @include shadow2;
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $white;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%) rotate(45deg);
    z-index: 0;
  }
}

.simple-tooltip-inner {
  background: $white;
  position: relative;
  z-index: 2;
  padding: $gutter / 2;
}

.accomodation-types {
  .dynamic-row {
    @include media('>tablet') {
      margin-left: 0;
    }
  }

  .auto-col.br-t ~ .auto-col.br-t {
    @include media('<=tablet') {
      margin-top: $gutter / 2;
    }
  }
}

.static-accommodation {
  max-width: 962px;
  margin: 0 auto;
}

.link-bar {
  @include spacing-m;
  line-height: 1;
  background: $white;
  color: $blue;
  border: 1px solid $border;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: ($gutter - 8px) $gutter;

  > li {
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 4px;

    &:not(:last-of-type) {
      margin-right: 12px;
    }

    > a,
    > button {
      color: inherit;

      &:hover {
        color: $orange;
        text-decoration: none !important;
      }
    }
  }
}

.contact-all {
  .hdr-sublist-item,
  .hdr-sublist-title {
    color: $blue;

    p,
    span {
      color: #969696;
    }
  }

  a:hover {
    color: $orange;
    text-decoration: none;
  }

  .col-6 + .col-6 {
    @include media('<=laptop') {
      margin-top: 20px;
    }
  }
}

.contact-icon-email,
.contact-icon-headset,
.contact-icon-schedule {
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-position: left center;
  background-repeat: no-repeat;

  @include media('>phone') {
    width: 48px;
    height: 48px;
    background-size: 48px;
  }
}

.contact-icon-email {
  background-image: svg-load('support_email.svg');
}

.contact-icon-headset {
  background-image: svg-load('support.svg');
}

.contact-icon-schedule {
  background-image: svg-load('working_hours.svg');
}

.contact-row {
  border-bottom: 1px solid $border;
  padding: 16px 0;
  margin-top: 0;

  &:first-of-type {
    border-top: 1px solid $border;
    margin-top: 30px;
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  h2,
  h3 {
    margin-bottom: 8px;

    aside & {
      font-size: 18px;
    }
  }
}

.warning-container {
  background: $sand;
  color: $red;
}

.thank-you {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;

  p {
    margin: 16px 0;
  }

  h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.1;
    margin: 25px 0;

    @include media('>tablet') {
      font-size: 36px;
    }

    @include media('>laptop') {
      font-size: 46px;
    }
  }

  .loyalty-checkout {
    border-radius: 6px;
    padding: 30px;

    @include media('<=tablet') {
      .loyalty-title-light {
        font-size: 18px;
      }
    }
  }
}

.modal-overlay .js-camp-map-container {
  @include media('<=phone') {
    min-height: 100%;
  }
}

.covid-page-layout {
  padding-top: 50px;
}

.notification-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #FFF4C7;

  @include media('<tablet') {
    font-size: 14px;
  }

  & + .container > .listing {
    margin-top: 40px;
  }
  
  a {
    color: $premium-text;

    &:hover {
      text-decoration: underline;
    }
  }
}

.notification-ribbon-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  background: transparent no-repeat scroll center center;

  &.notice {
    background-image: svg-load('notice.svg');
  }
}

.changePassword__FormWrapper {
  margin-top: 60px;
}