// ==========================================================================
// Listing base styles
// ==========================================================================

.listing {
  @include media('>phone') {
    @include negative-margin;
    flex-wrap: nowrap;
    display: flex;
  }

  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      left: $gutter / 2;
      right: $gutter / 2;
      bottom: -40px;
      background: #e0e0e0;
    }
  }

  &.filterable {
    display: none;

    &::before {
      content: none;
    }
  }

  &.filter-matches {
    display: flex;

    & ~ .filter-matches {
      &::before {
        content: '';
      }
    }
  }

  .rt-container {
    @include media('>tablet') {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.listing-img {
  position: relative;
  width: 100%;

  @include media('<=tablet', '>phone') {
    @include gridcol(1, 2);
    margin-bottom: 30px;
  }

  @include media('>tablet') {
    @include gridcol(1, 3);
  }

  > img {
    width: 100%;
    display: block;
  }

  > .inline-list {
    margin-top: 0.5em;
    font-size: 14px;
  }
}

.listing-body {
  @include media('>phone') {
    display: flex;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media('<=tablet', '>phone') {
    @include gridcol(1, 2);
  }

  @include media('>tablet') {
    @include gridcol(2, 3);
    flex-wrap: nowrap;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.listing-left {
  @include media('>tablet') {
    @include pad;
    // flex-basis: 0%;
    // flex-grow: 1;
    // flex-shrink: 1;
  }

  @include media('<=phone') {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  h3 {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

.listing-right {
  // min-width: 0;
  > * {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .btn {
    white-space: nowrap;
  }

  @include media('>tablet') {
    flex-basis: 220px;
    // flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
  }
}

.listing-left,
.listing-right {
  @include media('<=phone') {
    display: block;
    // text-align: center;
  }

  @include media('>phone') {
    @include pad;
  }
}

.listing-dates {
  margin-bottom: 0.5em;
}

.usp-why-us {
  font-weight: 700;
  font-size: 23px !important;
  border-bottom: 1px solid $border;
  padding-bottom: 1em;

  @include media('>phone') {
    font-size: 27px !important;
  }

  a {
    color: inherit;

    &:hover {
      color: $orange;
    }
  }

  &:not(:first-child) {
    margin-top: 0.2em;
  }
}

.listing-legend {
  background: $blue;
  display: table;
  color: $white;
  padding: 8px;
  font-size: 12px;
  line-height: 1.3;
  text-align: left;

  + .listing-legend {
    margin-top: 8px;
  }

  strong {
    display: block;
  }
}

.listing-availability {
  margin-top: $gutter / 2;
  margin-bottom: $gutter / 2;

  h4 {
    font-size: 14px;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.legend-orange {
  background: $orange;
}

.legend-item {
  display: inline-block;
  vertical-align: middle;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
}

.listing-beds {
  position: absolute;
  right: $gutter / 2;
  top: $gutter / 2;
  width: 44px;
  height: 44px;
  font-size: 12px;
  background: $green;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  padding: 5px;
  display: flex;

  > div {
    margin: auto;

    .icon {
      margin-top: -0.3em;
    }
  }
  flex-direction: column;
}

.section-header {
  margin-bottom: 50px;

  p {
    margin: 20px auto;
    font-size: 18px;
    max-width: colw(6);
    text-align: center;
  }
}

.italic-title {
  font-size: 20px;

  * {
    color: $blue;
    font-weight: 400;
    font-style: italic;
  }
}

.split-header {
  line-height: 1;
}

.split-header-main {
  @include media('>phone') {
    display: table-cell;
    width: 10000px;
    vertical-align: middle;
  }
}

.split-header-extra {
  text-transform: uppercase;

  @include media('>phone') {
    white-space: nowrap;
    display: table-cell;
    text-align: right;
    vertical-align: middle;
  }
}

.listing-tag-cloud {
  margin: 1em 0;
}

.listing-tag {
  display: inline-block;
  line-height: 1;
  text-transform: uppercase;
  font-size: 10px;
  padding: 5px;
  white-space: nowrap;
  color: $blue;
  border: 1px solid $blue;
  font-weight: 700;
}

.action-price-block {
  margin-top: 0 !important;

  & + .price-block {
    margin-top: 0;
  }
}

.price-block {
  line-height: 1;
  color: $blue;
  display: inline-table;
  font-size: 12px;
  white-space: nowrap;
  margin: 1em 0;

  @include media('<=tablet') {
    .price-block-copy,
    .price-block-price {
      width: auto;
    }
  }
}

.overlay-price-block {
  color: $white;
  right: $gutter / 2;
  bottom: $gutter / 2;
  left: $gutter / 2;
  z-index: 1;
}

.price-block-copy {
  text-transform: uppercase;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  padding-top: 0.3em;

  > span {
    display: block;
  }
}

.price-block-price {
  display: table-cell;
  vertical-align: middle;
  font-size: 3.3em;
  padding-left: 4px;
  line-height: 1;
  text-align: left;
  width: 1%;

  > span {
    font-size: 0.5em;
  }
}

.listing-actions {
  line-height: 1;

  @include media('>tablet') {
    text-align: right;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  .btn {
    max-width: 100%;
  }
}

.listing-actions-secondary {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 8px;

  > a {
    white-space: nowrap;

    &:not(:first-of-type) {
      margin-left: 6px;
    }
  }
}

.listing-stars {
  display: inline-flex;
  line-height: 1;
  color: #fb0;
  flex-wrap: nowrap;

  + .listing-stars {
    margin-left: $gutter;
  }
}

.listing-location {
  line-height: 1;
  text-transform: uppercase;
  font-size: 12px;
  color: $grey3;
  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: 4px;
  }
}

.special-offer-highlight {
  color: $green;
  font-weight: 400;
  font-size: 20px;
  margin-top: 30px;
  white-space: normal;

  > span {
    display: inline;
  }
}

.unit-special {
  @include spacing-m;
}

.unit-special-header-img {
  display: table-cell;
  vertical-align: bottom;
  line-height: 1;
}

.unit-special-header-copy {
  display: table-cell;
  vertical-align: bottom;
  line-height: 1;
  padding-left: $gutter;
}

.unit-special-option {
  margin-left: auto;
  margin-right: auto;

  &::before {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h4 {
    color: $grey3;
    font-size: 24px;
    font-weight: 400;
  }

  .price-block {
    color: $grey3;
  }

  @include media('<=phone') {
    .media-body,
    .media-right {
      display: block;
      width: auto;
      margin: 0 auto;
      padding: 0;
    }

    .price-block {
      margin-left: 0;
      text-align: left;
    }

    .price-block-copy {
      text-align: left;
    }
  }
}

.acc-type-actions {
  // min-width: 200px;
  + .acc-type-actions {
    &::before {
      margin: 14px auto;
      content: '';
      height: 1px;
      display: block;
      background: $border;
    }
  }

  > * + * {
    margin-top: 12px;
  }

  &.unavailable {
    * {
      color: $grey3;
    }
  }
}

.acc-type {
  color: $blue;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;

  > span {
    margin-right: 10px;
  }
}

// ==========================================================================
// Accommodation units
// ==========================================================================

.accommodation-listing {
  border: 1px solid $border;
  padding: 30px 18px;
  flex-wrap: wrap;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  &::after {
    content: none !important;
  }

  @include media('<=tablet') {
    flex-direction: column;

    & > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.accommodation-listing-img {
  width: 100%;
  max-width: colw(6);

  @include media('>tablet') {
    @include gridcol(1, 2);
  }
}

.accommodation-option {
  border-top: 1px solid $border;
  position: relative;
  font-size: 14px;
  background: $white;
  padding: $gutter / 2 $gutter / 2 0;
  margin-top: 20px !important;
  width: 100%;

  @include media('>laptop') {
    padding: $gutter 0;
    margin: 30px auto;
    display: flex;
    flex-wrap: nowrap;
  }

  > * {
    flex: 1 1 50%;
  }

  .bullet-list > li::before {
    width: 3px;
    height: 3px;
  }
}

.radio-rates {
  display: flex;
  align-items: center;

  .option-indicator {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-right: 10px;

    &::before {
      width: 10px;
      height: 10px;
    }
  }
}

.price-rates-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-end;
  padding: 0;

  @include negative-margin;

  @include media('<=tablet') {
    flex-direction: column-reverse;
  }
}

.price-rate {
  width: calc(50% - #{$gutter});
  min-height: 270px;
  background-color: $grey5;
  color: $grey1;
  margin: 0 $gutter/2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: center;

  @include media('<=tablet') {
    width: 100%;
    max-width: 280px;
    margin: 10px auto !important;
    min-height: auto !important;
  }

  &:not(.loyalty-price-rate):not(.withoutBookButton) .price {
    margin-bottom: 0;
  }
}

.loyalty-price-rate {
  min-height: 270px;
  background-color: $indigo;
  color: $white;
}

.price-rate-title {
  text-transform: uppercase;
  font-weight: 400;
  max-width: 80%;
  font-size: 16px;
}

.accommodation-body {
  display: block !important;
  width: 100%;
  max-width: colw(6);

  @include media('>tablet') {
    @include pad;
  }

  @include media('<=phone') {
    .btn {
      width: 100%;
    }
  }
}

.accommodation-title {
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 0.8em;

  @include media('>phone') {
    font-size: 27px;
  }

  > a {
    &:hover {
      color: $orange;
    }
  }
}

.listing-title {
  font-weight: 500 !important;
  font-size: 24px !important;
  margin-bottom: 0.8em !important;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  > a {
    &:hover {
      color: $orange;
    }
  }
}

.accommodation-option-title {
  position: relative;
  font-weight: 500 !important;
  font-size: 20px !important;

  &::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 33%;
    width: 6px;
    height: 8px;
    background-color: $orange;
  }
}

.accommodation-discount {
  position: absolute;
  left: -16px;
  top: 18px;
  z-index: 2;
  border-radius: 50%;
  color: $white;
  background: $green;
  padding: 6px;

  > .icon {
    display: block;
  }
}

.accommodation-features {
  font-size: 14px;
  color: $grey3;
  margin: 1em 0;

  > li {
    display: inline-block;
    padding-left: 10px;
    position: relative;
    white-space: nowrap;

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    &::before {
      content: '';
      border-radius: 50%;
      background: currentColor;
      width: 5px;
      height: 5px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2px;
    }
  }
}

.price {
  margin-top: auto;
  margin-bottom: auto;

  .tooltip-target {
    color: $grey4;
  }

  fieldset {
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.action-price {
  position: relative;
  color: #c50000;
  font-size: 18px;
  line-height: 1.67;

  &:after {
    position: absolute;
    top: calc(50% + 1px);
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c50000;
  }

  &.orange {
    color: #ff6f6f;

    &:after {
      background-color: #ff6f6f;
    }
  }
}

.with-action-price {
  align-items: flex-end;
}

.price-old {
  display: block;
  font-size: 16px;
  margin-top: 20px;

  &.is-hidden {
    display: none;
  }
}

// .price-old + fieldset {
//   margin-top: 10px;
//   padding-top: 15px;
//   border-top: 1px solid rgba(255, 255, 255, 0.3);
// }

.price-current-container {
  display: flex;
  align-items: center;
}

.price-current {
  font-size: 28px;
  line-height: 20px;
  font-weight: 700;
  margin-right: 6px;
}

.loyalty-saving {
  font-size: 16px;
  display: flex;
  margin-top: 20px;

  &::before,
  &::after {
    content: '-';
    margin: 0 4px;
  }
}

.price + .btn {
  margin-top: 20px;
  align-self: stretch;
}

// ==========================================================================
// Loyalty nights
// ==========================================================================

.ln-pick {
  @include cf;
  display: table;
  border: 1px solid $border;
  margin-top: 8px;
  line-height: 1;
  background: $white;

  @include media('<=phone') {
    width: 100%;
  }
}

.ln-pick-value {
  display: table-cell;
  vertical-align: middle;

  > select {
    border-radius: 0;
    display: block;
    border: 0;
    color: $green;
    font-weight: 700;
  }
}

.ln-pick-label {
  padding: 0 15px;
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  color: $blue;

  @include aa;

  > label {
    height: 46px;
    display: block;
    padding: 13px 0;
  }
}

// ==========================================================================
// Service cards
// ==========================================================================

.service-card-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.service-card-listing {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}

.service-card {
  @include gridcol(1, 4);
  margin-top: 30px;
  text-align: center;

  &:hover {
    color: $orange;
  }

  @include media('<=laptop') {
    @include gridcol(1, 2);
  }

  @include media('<=phone') {
    @include gridcol(1, 1);
  }
}

.service-card-title {
  font-weight: 400;
  font-size: 18px;
  margin-top: 6px;
}
