.bannerSection {
  margin-top: 80px;

  @include media('<tablet') {
    margin-top: 30px;
  }
}

.bannerImage {
  width: 100%;
}

.bannerImage--Desktop {
  @include media('<desktop') {
    display: none;
  }
}

.bannerImage--Tablet {
  display: none;

  @include media('<desktop', '>=tablet') {
    display: block;
  }
}

.bannerImage--Mobile {
  display: none;

  @include media('<tablet') {
    display: block;
  }
}