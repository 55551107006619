// ==========================================================================
// Styles for CMS-produced rich text
// ==========================================================================

.tiny-mce-pad {
  @include pad;
}

.rt-container {
  @include spacing-m;

  @for $i from 1 through 5 {
    h#{$i}:not([class]),
    .h#{$i} {
      @extend %h#{$i};

      // .gb-premium & {
      //   color: $premium-text;
      // }

      // .gb-sunny & {
      //   color: $sunny-text;
      // }

      // .gb-resort & {
      //   color: $resort-text;
      // }

      // &:not(:first-child) {
      //   margin-top: 1em;
      // }
    }

    h1,
    .h1 {
      &:not(:first-child) {
        margin-top: 8rem;
      }

      margin-bottom: 2rem;
    }

    h2,
    .h2 {
      &:not(:first-child) {
        margin-top: 6rem;
      }
      margin-bottom: 2rem;
    }

    h3,
    .h3 {
      &:not(:first-child) {
        margin-top: 4rem;
      }
      margin-bottom: 2rem;
    }

    h4,
    .h4 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
      margin-bottom: 1rem;
    }

    h5,
    .h5 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.6;

    > a {
      color: $orange;

      &:hover {
        text-decoration: underline;
      }
    }

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  a:not([class]) {
    &:hover {
      text-decoration: underline;
    }
  }

  pre {
    padding: 1em 20px;
    display: block;
    border-radius: 5px;
    background: #e6e6e6;
    font-family: 'Consolas', 'Menlo', monospace;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 2rem;
    font-weight: 400;
    overflow-x: auto;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  code {
    display: inline;
    border-radius: 5px;
    background: #e6e6e6;
    padding: 4px 8px;
    font-size: 16px;
    font-weight: 400;
  }

  .flex-row {
    > * {
      @include media('>phone') {
        max-width: 50%;
      }
    }
  }

  .rt-col {
    @include media('>phone') {
      max-width: 50%;
      float: left;
    }

    @include media('<=phone') {
      margin-bottom: $gutter;
    }

    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }

  .split-column-row {
    column-gap: $gutter;

    @include media('>tablet') {
      columns: 2;
    }
  }

  ul {
    &:not([class]) {
      @extend %bullet-list;
    }
  }

  ol {
    &:not([class]) {
      @extend %ordered-list;
    }
  }

  .row,
  .flex-row {
    @include spacing-m;
  }

  .inline-list {
    &:not(.bullet-list) {
      > li {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  hr {
    /* stylelint-disable-next-line no-descending-specificity */
    + * {
      margin-top: 0 !important;
    }
  }
}

.flex-row.row-no-margin {
  margin: 0;

  @include media('<=col-8') {
    display: block;
  }

  > div {
    margin: 0;

    @include media('<=col-8') {
      max-width: 100%;
    }
  }
}

.col-color {
  @include aa;
  color: $white;
  background: $green;
  padding: $gutter;

  > *:last-child {
    margin: 0 !important;
  }

  .gb-premium & {
    background: $premium-basic;
  }

  .gb-sunny & {
    background: $sunny-basic;
  }

  .gb-resort & {
    background: $resort-basic;
  }
}
