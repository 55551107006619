.innature-infos-grid {
  margin-bottom: 100px;
  position: relative;
  background: transparent no-repeat scroll center center;
  background-size: cover;

  @include media('<tablet') {
    margin-bottom: 50px;
  }
}

.innature-container {
  display: flex;

  @include media('<desktop') {
    max-width: 720px;
  }

  @include media('<tablet') {
    flex-direction: column;
    padding: 0;
  }
}

.innature-intro-wrapper {
  max-width: 270px;
  width: 100%;
  margin-right: 24px;
  flex-shrink: 0;

  @include media('<desktop') {
    max-width: 350px;
  }

  @include media('<tablet') {
    max-width: 100%;
    margin-right: 0;
  }
}

.innature-intro {
  display: flex;
  align-items: center;
  flex-direction: column;
  
  height: calc(100% + 48px);
  position: relative;
  top: -24px;
  padding: 75px 20px 20px;
  background-color: #fff;
  border: solid 1px #d5d5d5;

  @include media('<tablet') {
    margin: 25px 15px 0;
    top: 0;
    border: none;
    height: auto;
    padding: 20px;
    align-items: flex-start;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }

  h2,
  p {
    width: 100%;
  }
}

.innature-intro-text {
  width: 100%;
  
  p,
  ul,
  ol {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  ul,
  ol {
    margin-top: 10px;
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: numbers;
  }
}

.innature-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 45px;
  padding-bottom: 45px;

  @include media('>=desktop') {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  @include media('<desktop') {
    justify-content: space-between;
    flex-direction: column;
  }

  @include media('<tablet') {
    padding: 15px;
  }
}

.innature-grid-item,
.innature-button {
  width: calc(33.33% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;

  @include media('<desktop') {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.innature-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 133px;
  padding: 20px 17px 15px 20px;
  background-color: #003f5d;
  color: #fff;
  line-height: 1.38;

  @include media('>=desktop') {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include media('<desktop') {
    min-height: 83px;
    padding: 20px;
  }

  @include media('<tablet') {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.innature-grid-item-text {
  width: 100%;

  h2, h3, h4, h5 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  p,
  ul,
  ol,
  img {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  img {
    width: 100%;
  }
}

.innature-grid-item-icon {
  flex-shrink: 0;
  width: 69px;
  height: 50px;
  margin-top: 15px;
  align-self: flex-end;
  background: transparent no-repeat scroll center bottom;
  background-size: 100%;

  @include media('<desktop') {
    display: none;
  }

  &.space {
    background-image: svg-load('space.svg');
  }
  
  &.delivery {
    height: 37px;
    background-image: svg-load('delivery.svg');
  }

  &.safety {
    width: 89px;
    background-image: svg-load('safety.svg');
    background-size: 100%;
  }

  &.online {
    width: 42px;
    height: 70px;
    background-image: svg-load('online.svg');
  }

  &.healthy {
    width: 33px;
    background-image: svg-load('healthy.svg');
  }

  &.vcare {
    width: 102px;
    height: 40px;
    background-image: svg-load('vcare.svg');
  }
}

.innature-button {
  align-self: flex-end;
  padding: 6px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;

  @include media('<tablet') {
    margin-bottom: 20px;
  }
}