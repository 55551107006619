.news-gallery-container {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    .slick-custom-arrow {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 50%;
        z-index: 2;
        background-color: rgba($blue, 0.9);
        border-radius: 50%;
        transform: translateY(-50%);
        font-size: 0;
        line-height: 1;

        &:hover {
            background-color: $blue-white-70;
        }

        @include media('<tablet') {
            display: none;
        }
    }

    .slick-prev-arrow {
        left: -17px;

        @include media('<1200px') {
            left: -10px;
        }

        svg {
            transform: translateX(-2px);
        }
    }

    .slick-next-arrow {
        right: -17px;

        @include media('<1200px') {
            right: -10px;
        }

        svg {
            transform: translateX(1px);
        }
    }
}

.news-wrapper {
    @include media('>=tablet') {
        margin-left: -12px;
        margin-right: -12px;
    }

    &.left-align-slick > .slick-list > .slick-track {
        margin-left:0;
    }
    
    .news-single {
        position: relative;

        @include media('<tablet') {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
        }

        &.over-limit {
            @include media('<tablet') {
                display: none;
            }
        }
    }

    .slick-slide {
        margin-left: 12px;
        margin-right: 12px;
    }

    .news-single-image {
        display: block;
        width: 100%;
    }

    .news-single-title {
        margin-top: 20px !important;
        margin-bottom: 15px !important;
        font-size: 16px;
        font-weight: 700;
    }

    .news-single-copy {
        margin-top: 0 !important;
        color: #51515a;
    }

    .news-single-link {
        &:hover {
            .news-single-image {
                opacity: 0.9;
            }

            .news-single-title {
                color: $orange;
            }
        }
    }
}

.load-more-button {
    display: none;

    @include media('<tablet') {
        display: inline-block;
    }

    &.show {
        .more {
            display: none;
        }

        .less {
            display: inline;
        }
    }

    .less {
        display: none;
    }
}
