// Header generic sublist
// ======================
.hdr-sublist {
  color: $blue;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
}

a.hdr-sublist-item,
a.hdr-sublist-title {
  &:hover {
    color: $orange;
  }
}

.hdr-sublist-text,
.hdr-sublist-item,
.hdr-sublist-title {
  white-space: normal;
  color: $blue;
  padding: 10px 0 11px;

  &.nbb {
    border-bottom: 0;
  }
}

.hdr-sublist-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.hdr-sublist-item {
  display: block;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.without-border {
    border-bottom: none;
  }
}

.hdr-sublist-row {
  display: flex;
  width: 100%;
  align-items: center;

  > .icon {
    margin-right: 12px;
  }
}

.hdr-sublist-item {
  width: 100%;
  // display: flex;
  font-size: 14px;
  color: $blue;

  .location {
    color: #969696;
    margin-right: 5px;
  }

  .info {
    margin-right: auto;
    flex-basis: 50%;
    padding-right: $gutter / 2;
  }

  .info-location {
    color: #969696;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    text-align: left;
  }

  .types {
    display: flex;
    justify-content: flex-end;
  }
}

.hdr-sublist-item + .hdr-sublist-title,
.hdr-sublist-title + .hdr-sublist-title {
  margin-top: 20px;
}

.hdr-sublist-title.without-spacing + .hdr-sublist-item {
  padding-top: 0;
}

.hdr-sublist-title {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;

  > a,
  > span {
    font-weight: 400;
    white-space: nowrap;
    padding-left: 8px;
  }

  > p {
    margin-top: 10px;
    font-weight: 400;
    line-height: 24px;
  }

  &.without-border {
    border-bottom: none;
  }

  &.without-spacing {
    margin: 0;
    padding: 0;
  }
}

.hdr-sublist-map {
  position: relative;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: auto;
  width: 266px;
  height: 266px;

  .modal-content & {
    display: none !important;
  }

  .pins {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span {
      width: 14px;
      height: 14px;
      margin: 0 0 -5px -5px;
      display: block;
      position: absolute;
      background: $orange;
      border: 2px solid #fff;
      border-radius: 50%;
      opacity: 0;
      visibility: hidden;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: opacity 160ms ease, visibility 160ms ease,
        transform 160ms ease;
      will-change: opacity, visibility, transform;

      &.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }
}

// Header desktop sublist
// ======================
.hdr-sublist-inner {
  position: relative;
  width: 100%;
  background: $white;
  padding: $gutter;
  pointer-events: all;

  @include media('>tablet') {
    @include shadow2;
  }
}

.hdr-sublist-map {
  display: none;

  @include media('>laptop') {
    .no-touchevents & {
      display: block;
    }
  }
}

// Header mobile sublist
// =====================
.hdr-m {
  .hdr-sublist {
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0;
    top: 0;
    display: none;

    &::before {
      content: none;
    }

    &.is-open {
      display: block;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }

      &:active {
        color: $orange;
      }
    }
  }

  .hdr-sublist-text,
  .hdr-sublist-item,
  .hdr-sublist-title {
    padding: 10px ($gutter / 2) 11px;
  }

  .hdr-sublist-subgroup {
    > a {
      display: block;
      font-weight: 700;
      white-space: normal;
      padding: 10px ($gutter / 2) 11px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: 0;
      }

      > .icon {
        vertical-align: middle;
        margin-top: -1px;
        margin-left: 2px;
      }
    }
  }

  .hdr-sublist-map {
    display: none !important;
  }
}

// Hacky modal / dropdown / nothing
// ================================
.modal-content {
  .hdr-sublist-inner {
    position: relative;
    max-width: colw(10);
    padding-bottom: 33px;
    margin: 0 auto;
    box-shadow: none;

    @include cf;
    color: $blue;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;

    a {
      color: inherit;

      &:hover {
        color: $orange;
      }
    }

    .hdr-sublist-subgroup {
      padding-top: 10px;

      a {
        display: inline-block;
        vertical-align: top;
        margin: 20px 20px 0 0;

        > .icon {
          vertical-align: middle;
          margin-top: -1px;
          margin-left: 2px;
        }
      }
    }

    .hdr-sublist-map {
      display: none !important;
    }
  }
}
