.camping-map {
  @include spacing-m;
  position: relative;

  @include media('>tablet') {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.camping-map-nav {
  position: relative;
  padding: 16px;
  z-index: 2;
  min-width: colw(2);
  color: $blue;
  background: $white;
  font-size: 14px;

  @include media('>tablet') {
    @include shadow2;
    align-self: flex-start;
    margin-left: $gutter;
    margin-top: 70px;
    margin-bottom: 70px;
  }

  > .listing-location {
    display: block;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }

  > ul {
    > li {
      margin-top: 8px;
    }
  }

  a {
    color: inherit;
    display: flex;
    align-items: center;

    > span {
      white-space: nowrap;

      &:first-of-type {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 70%;
        margin-right: 20px;
      }
    }

    &:hover {
      color: $orange;
    }
  }
}

.camping-map-frame {
  @include divfill;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: 50% 50%;

  @include media('<=tablet') {
    display: none;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.map-bubble {
  position: absolute;

  @include shadow2;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $white;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%) rotate(45deg);

    @include shadow2;
    z-index: 0;
  }
}

.map-bubble-inner {
  background: $white;
  position: relative;
  z-index: 2;
  padding: $gutter / 2;

  .listing-stars {
    display: block;
  }

  .listing-legend {
    padding: 5px;
  }

  > * + * {
    margin-top: 6px;
  }

  a {
    &:hover {
      color: $orange;
    }
  }
}
