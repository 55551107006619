// ==========================================================================
// These are utility classes that should be used only in small quantities
// ==========================================================================

.table-outer {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  position: relative;
}

.table-inner {
  display: table-cell;
  vertical-align: middle;
}

.scrollable {
  .card {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include media('<=tablet') {
    @include negative-margin;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table {
      margin-left: $gutter / 2;
      margin-right: $gutter / 2;
      min-width: colw(6);
    }

    [class^='col'],
    .auto-col {
      width: 60vw;
    }
  }

  @include media('<=phone') {
    [class^='col'],
    .auto-col {
      width: 90vw;
    }
  }
}

// .grey-bg {
//   background: $grey5;
//   overflow: hidden;
// }

.valign {
  @include valign-center;

  .rt-container & {
    &:hover {
      text-decoration: none;

      > span:first-of-type {
        text-decoration: underline;
      }
    }
  }
}

.blocklink {
  display: block;
  color: inherit;

  > img {
    display: block;
    width: 100%;
  }

  &:hover {
    // color: $orange;

    img {
      opacity: 0.9;
    }
  }

  .rt-container & {
    &:hover {
      text-decoration: none;
    }
  }
}

.u-uppercase {
  text-transform: uppercase;
}

.u-bold {
  font-weight: bold;
}

.u-color-inherit {
  color: inherit;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.normal-copy {
  font-weight: 400 !important;
}

.center-copy {
  text-align: center !important;
}

.right-copy {
  text-align: right !important;
}

.left-copy {
  text-align: left !important;
}

.u-center-titles {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
}

.blue-copy {
  color: $blue !important;
}

.green-copy {
  color: $green !important;
}

.red-copy {
  color: $red !important;
}

.orange-copy {
  color: $orange !important;
}

.light-copy {
  color: $grey4 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.wrap {
  white-space: auto !important;
}

.sr-only {
  @include sr-only;
}

.u-inline {
  display: inline-block;
  text-align: center;
}

.simple-spacing {
  > * + * {
    margin-top: 0.875em;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.spacing-l {
  @include spacing-l;
}

.spacing-m {
  @include spacing-m;
}

.spacing-s {
  @include spacing-s;
}

.spacing-20-top {
  margin-top: 20px !important;
}

.spacing-m-top {
  margin-top: $spacing_size_m !important;
}

.spacing-m-bot {
  margin-bottom: $spacing_size_m !important;
}

.spacing-l-bot {
  margin-bottom: 48px !important;
}

.spacing-l-top {
  margin-top: 48px !important;
}

.spacing-s-top {
  margin-top: $spacing_size_s !important;
}

.spacing-s-bot {
  margin-bottom: $spacing_size_s !important;
}

.u-font-zero {
  font-size: 0;
}

.divfill {
  @include divfill;
}

.is-hidden {
  display: none !important;
}

.u-line-through,
.u-line-through * {
  text-decoration: line-through !important;
}

.u-stacks-m {
  @include media('<=phone') {
    & + .u-stacks-m {
      margin-top: $gutter;
    }
  }
}

.u-stacks-t {
  @include media('<=tablet') {
    & + .u-stacks-t {
      margin-top: $gutter;
    }
  }
}

.u-t-none {
  @include media('<=tablet') {
    display: none !important;
  }
}
