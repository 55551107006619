// ==========================================================================
// Checkout page specifics
// ==========================================================================

.checkout-grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.checkout-main {
  @include col;

  @include media('>tablet') {
    width: percentage(6 / 12);
  }

  .form-h2 {
    color: #323232;
    font-size: 28px;
  }
}

.checkout-intro {
  font-size: 18px;
  color: #999;

  @include media('>tablet') {
    font-size: 22px;
  }
}

.checkout-aside {
  @include pad;
  margin-left: auto;
  position: relative;

  @include media('>tablet') {
    width: percentage(6 / 12);
    max-width: colw(4);
  }

  .def-title {
    font-size: 13px;
  }
}

.checkout-actions {
  @include pad;
  clear: both;
  padding-bottom: 30px;
  border-bottom: 1px solid $grey4;

  @include media('>phone') {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .btn {
    max-width: 350px;
    min-width: 0;
    width: 100%;
    height: auto;
    vertical-align: top;
    margin-top: 10px;
  }

  &.top-actions {
    margin-bottom: 30px;

    .btn {
      margin: 0;
    }
  }
}

.checkout-actions-back,
.checkout-actions-confirm {
  text-align: center;

  @include media('>phone') {
    display: table-cell;
  }
}

.checkout-actions-back {
  @include media('>phone') {
    text-align: left;
    width: 160px;
  }
}

.checkout-actions-confirm {
  @include media('>phone') {
    border-left: $gutter/2 solid rgba(0, 0, 0, 0);
  }
}

.booking-summary {
  background: $white;
  border-radius: 4px;
  border: 1px solid $green;
  padding: $gutter;
  position: relative;

  .aside & {
    @include media('>tablet') {
      max-width: colw(4);
    }
  }
}

.checkout-aside-inner {
  // position: relative;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.booking-summary-title {
  color: $green;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 1em;
}

.booking-title {
  margin-top: 14px;
}

.booking-subtitle {
  margin-bottom: 10px;
  line-height: 1.3;
  color: $grey1;

  &:last-of-type {
    margin-bottom: 18px;
  }
}

.def-group {
  @include pad;
  display: table-cell;
  padding-bottom: 14px;
}

.def-title {
  color: $green;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.booking-summary-block {
  border-top: 2px dashed $green;
  padding-top: 14px;

  s {
    color: $grey4;
  }

  &:not(:last-of-type) {
    padding-bottom: 14px;
  }

  .flex-row {
    justify-content: space-between;

    & + & {
      margin-top: 10px;
    }
  }
}

.booking-info {
  &:not(button) {
    color: $green;
  }
  font-size: 14px;
}

.booking-faq {
  @include spacing-m;
  font-size: 14px;

  // @include media('>tablet') {
  //   clear: both;
  //   max-width: colw(4);
  //   float: right;
  // }
}
