// ==========================================================================
// White boxes with borders around 'em
// ==========================================================================

.card {
  @include spacing-m;
  position: relative;
  background: $white;
  box-shadow: 0 0 0 1px $border;
  width: 100%;

  &.is-left {
    @include media('>tablet') {
      padding-left: 50%;
    }
  }

  &.is-right {
    @include media('>tablet') {
      padding-right: 50%;
    }
  }
}

.card-no-margin {
  margin: 0;
  border-top: 0;
}

.card-img-top {
  position: relative;

  > img {
    @include img-fluid;
  }
}

.card-img-top-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  color: #fff;

  h1 {
    color: inherit;

    @include media('<=tablet') {
      font-size: 38px;
    }

    @include media('<=phone') {
      font-size: 28px;
    }
  }

  .flickity-enabled & {
    pointer-events: none;
  }

  ~ .flickity-prev-next-button {
    z-index: 2;
  }

  .icon-special_offers {
    max-height: 25%;
  }
}

.flickity-prev-next-button {
  width: 60px;
  height: 60px;
  display: flex;
  &,
  &:hover {
    background: $green-dark;
  }

  @include media('<=phone') {
    width: 40px;
    height: 40px;
    top: 60%;
  }

  svg {
    width: 24px;
    height: 24px;
    margin: auto;
    position: relative;
    top: auto;
    left: auto;
  }

  .arrow {
    fill: $white;
  }
}

.card-img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba($black, 0), rgba($black, 0.3));

  .container {
    display: flex;
  }
}

.card-img-split {
  line-height: 1;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  background-size: cover;
  background-position: 50% 50%;
  height: 200px;

  @include media('>tablet') {
    .card.is-left &,
    .card.is-right & {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
    }

    .card.is-left & {
      left: 0;
    }

    .card.is-right & {
      left: 50%;
    }
  }
}

.card-img-links {
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // background: $blue;
  padding: 10px;
  line-height: 1;
  margin-left: auto;

  .btn-link {
    color: $white;
    display: inline-block;

    &:hover {
      text-decoration: none;

      > * {
        text-decoration: none !important;
      }
    }

    > .icon {
      vertical-align: middle;
    }
  }
}

.card-body {
  padding: $gutter;
}

.card-carousel-gallery.is-special-offer,
.card-carousel-gallery-mobile.is-special-offer {
  padding-bottom: 0;
  height: auto;

  @include media('>tablet') {
    min-height: 440px;
  }

  .card-img-top-title {
    position: relative;

    .table-inner {
      padding: $gutter;

      @include media('>tablet') {
        height: 440px;
      }
    }

    .inner {
      background: rgba($green, 0.9);
      padding: 50px $gutter;
      margin: 0 auto;
      width: 768px;
      max-width: 100%;
      border-radius: 10px;
      color: #fff;
    }

    .category {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 4px;
    }

    .date {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}
