/** Extends sanitize.css */

// kill focus outline
*:focus {
  outline: none;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
}

h1 {
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  display: inherit;
}

html {
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: scrollbar;
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 320px;
}

// input styling
button,
input,
label,
select,
textarea {
  display: block;
  border-style: none;
  background-color: transparent;
  color: currentColor;
  text-transform: inherit;
  font-family: inherit;
  line-height: inherit;
  appearance: none;
  margin: 0;
  border-radius: 0;
}

::-webkit-file-upload-button {
  font-family: inherit;
  line-height: inherit;
  -webkit-appearance: button;
}

// prevent events propagating to children
a,
button,
label {
  > * {
    pointer-events: none;
  }
}

a {
  color: inherit;
}

button {
  box-shadow: none;
  user-select: none;
}

input {
  -webkit-border-radius: 0; // iOS 8+
  cursor: auto;

  &[type='search'] {
    appearance: none;
  }
}

pre {
  overflow: auto; // Internet Explorer 11-
}

ol,
ul {
  list-style-type: none;
}

select {
  &::-ms-expand {
    display: none; // Edge 12+, Internet Explorer 11-
  }

  &::-ms-value {
    color: currentColor; // Edge 12+, Internet Explorer 11-
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

figure {
  margin: 0; // required
}

legend {
  -webkit-margin-collapse: separate;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

:invalid {
  box-shadow: none;
}

::-ms-clear {
  display: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled],
[disabled] {
  cursor: default;
}

[tabindex='-1']:focus {
  outline: none;
}

[type='hidden'] {
  display: none;
}

[hidden] {
  &[aria-hidden='false'] {
    @include sr-only;
  }
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: none !important;
}
