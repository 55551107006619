// ==========================================================================
// Custom checkboxes and radio buttons
// ==========================================================================

.option {
  display: table;
  margin-top: 0.5em;

  > input {
    @include sr-only;

    &:checked {
      + .option-indicator {
        border-color: $green;

        &::before {
          opacity: 1;
        }
      }
    }

    &:focus,
    &:hover {
      + .option-indicator {
        border-color: $green;
        border-width: (2 / 16) * 1em;
      }
    }
  }
}

.option-inline {
  display: inline-table;

  &:not(:last-of-type) {
    margin-right: 20px;
  }
}

.option-label {
  display: table-cell;
  vertical-align: top;

  > a,
  > button {
    pointer-events: all;
  }
}

.option-indicator {
  margin-right: 6px;
  width: (20 / 16) * 1em;
  height: (20 / 16) * 1em;
  border: (1 / 16) * 1em solid $border;
  background: $white;
  margin-top: 0.15em;
  position: relative;
  vertical-align: top;

  &::before {
    content: '';
    color: $green;
    position: absolute;
    width: (18 / 16) * 1em;
    height: (18 / 16) * 1em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}

.option-checkbox {
  > .option-indicator {
    border-radius: (3 / 16) * 1em;

    // checkbox svg
    &::before {
      background-image: svg-load('material/check.svg', fill=$green);
      background-position: 50% 50%;
      background-size: 1em;
    }
  }
}

.option-radio {
  > .option-indicator {
    border-radius: 50%;

    &::before {
      background: currentColor;
      border-radius: 50%;
      width: (10 / 16) * 1em;
      height: (10 / 16) * 1em;
    }
  }
}
