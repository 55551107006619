// ==========================================================================
// Generic expandable content and trigger
// ==========================================================================

.expandable-content {
  overflow: hidden;
  position: relative;

  &:not(.accordion-open) {
    height: 0;
    padding-bottom: 1.5em;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2em;
      background: linear-gradient(rgba(0, 0, 0, 0), $white);
    }
  }
}

.expandable-trigger {
  margin-top: 8px;

  &.accordion-open {
    .trigger-open {
      display: none;
    }

    .trigger-close {
      display: block;
    }
  }
}

.trigger-close {
  display: none;
}
