// ==========================================================================
// Form widgets that are stuck together in the same line without spacing.
// ==========================================================================

.input-group {
  display: table;
  width: 100%;
  position: relative;
}

.input-group-item {
  display: table-cell;
  vertical-align: top;

  > * {
    display: block;
    width: 100%;

    &.btn {
      min-width: 0;
    }
  }

  &:not(:first-child) {
    > * {
      border-left: 0;
    }
  }

  &:first-child:not(:last-child) {
    > * {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:last-child:not(:first-child) {
    > * {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:not(:last-child):not(:first-child) {
    > * {
      border-radius: 0;
    }
  }
}

.input-group-primary {
  width: 100%;
}

.input-group-addon {
  width: 1%;
}

.input-group-fixed {
  table-layout: fixed;
}

.passwordInputField__Wrapper {
  position: relative;
}

.passwordInputToggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  width: 44px;
  cursor: pointer;
  background: transparent svg-load('password-visible.svg') no-repeat scroll center center;

  &.passwordVisible {
    background-image: svg-load('password-invisible.svg');
    background-position: center 53%;
  }
}