.table-simple {
  @include spacing-m;
  width: 100%;
  text-align: center;

  tr {
    border-bottom: 1px solid $border;

    + tr {
      > th {
        text-align: left;
      }
    }
  }

  th {
    text-transform: uppercase;
    font-weight: 400;
  }

  th,
  td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.table-white {
  @include spacing-m;
  width: 100%;
  background: $white;
  font-size: 14px;

  td,
  th {
    border: 1px solid $border;
    padding: 6px 8px;
  }
}

.table-colored {
  @include spacing-m;
  width: 100%;
  border: 1px solid $border;
  line-height: 1.2;
  text-align: center;

  th,
  td {
    border: 1px solid $border;
    padding: 8px 10px;
  }

  thead {
    th {
      background: $green;
      color: $white;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  tbody {
    background: $white;

    th {
      background: $grey5;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      text-align: left;
    }
  }
}

.definition-table {
  @include spacing-m;
  width: 100%;
  text-align: left;

  th,
  td {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }

  th {
    text-transform: uppercase;
    color: $grey3;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    width: 1%;
    padding-right: $gutter;
  }

  @include media('<=phone') {
    display: block;

    tr {
      display: block;

      + tr {
        margin-top: 0.8em;
      }
    }

    th,
    td {
      display: block;
      padding: 0;
      width: auto;
    }
  }
}

.value-highlight {
  color: $green;
  font-weight: 700;
  font-size: 20px;
}

.pricing-table-s {
  color: $blue;
  table-layout: fixed;
  width: 100%;

  caption {
    text-transform: uppercase;
    padding-bottom: 8px;
  }

  tr {
    &:not(:first-of-type) {
      border-top: 1px solid $border;
    }
  }

  td {
    padding-top: 5px;
    padding-bottom: 5px;

    &:not(:first-of-type) {
      padding-left: 8px;
      text-align: right;
    }
  }
}
