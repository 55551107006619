// ==========================================================================
// Yep. It's an icon.
// ==========================================================================

.icon {
  // display: inline-block;
  // vertical-align: text-bottom;
  fill: currentColor;
  position: relative;
  pointer-events: none;
}
