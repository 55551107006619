// ==========================================================================
// Variables go here
// ==========================================================================

// GRID
$gutter: 24px;
$col: 74px;
$ncol: 12;

// GREYS
$white: #fff;
$grey1: #323232;
$grey2: #646464;
$grey3: #969696;
$grey4: #bebebe;
$grey5: #f5f5f5;
$black: #000;

// BRAND COLORS
//
$premium-text: #0a506e;
$premium-basic: #0a6e96;
$premium-bg: #b4e6f0;
//
$resort-text: #006478;
$resort-basic: #00b4b4;
$resort-bg: #c8f0f0;
//
$sunny-text: #137b27;
$sunny-basic: #78b432;
$sunny-bg: #dcf0d2;

// COLORS
$orange: #ff6400;
$blue: #005c88;
$green: #159934;
$green-dark: #05423d;
$sand: #fafafa;
$lightblue: #e3f2fd;
$indigo: #253788;
$indigo-white-50: mix($indigo, $white, 50%);
$blue-white-30: mix($blue, $white, 30%);
$blue-white-70: mix($blue, $white, 70%);
$green-white-30: mix($green, $white, 30%);
$indigo-dark: #1C115D;

// OTHER
$red: #d9534f;
$border: #e0e0e0;
$yellow: #f0e2aa;

// MAIN BREAKPOINTS
$breakpoints: (
  'phone': 480px,
  'tablet': 768px,
  'laptop': 992px,
  'col-8': 784px,
  'desktop': 1200px
);

// DEFAULT SPACINGS
$spacing_size_s: 12px;
$spacing_size_m: 30px;
$spacing_size_l: 46px;
