.award-list {
  @include spacing-m;
  text-align: center;
  line-height: 1;
  font-size: 0;
  // overflow-x: hidden;
}

.award-item {
  display: inline-block;
  position: relative;

  &.decorated {
    background-image: svg-load('art/star_award_background.svg');
    width: 128px;
    height: 128px;
    padding: 32px;
  }

  &:not(:last-of-type) {
    margin-right: $gutter / 2;
  }

  > img {
    display: block;
  }

  &:hover {
    .award-item-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.award-item-tooltip {
  @include shadow2;
  position: absolute;
  font-size: 14px;
  z-index: 20;
  min-width: 210px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 20px;
  visibility: hidden;
  line-height: 1.2;
  opacity: 0;
  transition: visibility 180ms ease, opacity 180ms ease;

  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    height: 20px;
    left: 0;
    right: 0;
  }

  &::after {
    @include shadow2;
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $white;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%) rotate(45deg);
    z-index: 0;
  }
}

.award-item-tooltip-inner {
  background: $white;
  position: relative;
  z-index: 2;
  padding: $gutter / 2;
}
