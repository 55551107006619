// ==========================================================================
// Pikaday
// ==========================================================================

.pika-single {
  @include shadow2;
  position: absolute;
  background: $white;
  margin-top: 12px;
  z-index: 1000;

  .datepicker & {
    position: relative;
    display: block;
    box-shadow: none;
    margin: 0;
  }
}

.pika-lendar {
  width: auto;
  text-align: center;
  display: table-cell;
  padding: 8px;
  position: relative;

  button,
  select {
    padding: 0;
    border: 0;
    height: auto;
    background: initial;
    border-radius: 0;
  }

  abbr {
    text-decoration: none;
    font-weight: 400;
    color: $grey3;
    font-size: 12px;
  }

  .pika-title {
    margin-bottom: 12px;
    color: $blue;
    font-weight: 700;
  }

  .pika-date-btn {
    position: absolute;
    top: 9px;
    cursor: pointer;
    color: $blue;

    &.is-disabled {
      color: $grey3;
      pointer-events: none;
    }

    &:hover {
      color: $orange;
    }
  }

  .pika-prev,
  .pika-next {
    width: 30px;
    height: 25px;
    position: absolute;
    top: 8px;
    text-indent: -9999px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: $blue;
      background: transparent no-repeat scroll center center;
    }
  }

  .pika-prev {
    left: 6px;

    &:before {
      background-image: svg-load('pikaday-arrow-left.svg', fill=$blue);
    }

    &:hover:before {
      background-image: svg-load('pikaday-arrow-left.svg', fill=$orange);
    }
  }

  .pika-next {
    right: 6px;

    &:before {
      background-image: svg-load('pikaday-arrow-right.svg', fill=$blue);
    }

    &:hover:before {
      background-image: svg-load('pikaday-arrow-right.svg', fill=$orange);
    }
  }

  .pika-label {
    display: inline-block;
    position: relative;

    &:hover {
      color: $orange;
    }

    &:first-of-type {
      &::after {
        content: '\00a0';
        display: inline-block;
      }
    }
  }

  .pika-select {
    @include divfill;
    opacity: 0;
    cursor: pointer;
  }

  .pika-table {
    table-layout: fixed;
    width: colw(3);

    @include media('<=phone') {
      width: colw(3) - ($gutter * 2);
    }

    td {
      border: 1px solid $border;
    }
  }

  .pika-day {
    padding: 6px;
    font-size: 14px;

    &:hover {
      background: mix($green, $white, 7%);
    }
  }

  .is-today {
    > .pika-day {
      color: $orange;
      font-weight: 700;
    }
  }

  .is-disabled {
    > .pika-day {
      background: mix($black, $white, 7%);
      color: $grey4;
      cursor: default;
    }
  }

  .is-inrange {
    border: 1px double $green !important;

    > .pika-day {
      background: mix($green, $white, 60%);
      color: $white;
    }

    &:hover {
      > .pika-day {
        background: mix($green, $white, 68%);
        color: $white;
      }
    }
  }

  .is-startrange {
    border: 1px double $green !important;

    > .pika-day {
      background: mix($green, $white, 60%);
      color: $white;
    }
  }

  .is-endrange {
    border: 1px double $green !important;

    > .pika-day {
      background: mix($green, $white, 60%);
      color: $white;
    }
  }

  .is-selected {
    border: 1px double $green !important;

    > .pika-day {
      background: $green;
      color: $white;
    }
  }

  .pika-button {
    width: 100%;
    cursor: pointer;
  }
}

// ==========================================================================
// Dropdown
// ==========================================================================

.datepicker {
  @include shadow2;
  @include cf;
  position: absolute;
  background: $white;
  z-index: 3;
  top: 100%;
  margin-top: 27px;
  padding: 8px;
  visibility: hidden;
  transform-origin: top center;
  contain: content;

  &.datepicker-visible {
    visibility: visible;
  }

  @include media('>laptop') {
    left: 50%;
    transform: translateX(-50%);

    &.datepicker-visible {
      transform: translateX(-50%);
    }
  }
}
