// ==========================================================================
// Custom bullet lists, ordered lists, etc
// ==========================================================================

%bullet-list {
  &:not(:first-child) {
    margin-top: 2rem;
  }
  margin-bottom: 2rem;

  > li {
    position: relative;
    padding-left: 16px;

    &:not(:last-of-type) {
      margin-bottom: 0.3em;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      border-radius: 50%;
      background: currentColor;
      width: 6px;
      height: 6px;
    }
  }
}

%ordered-list {
  counter-reset: ol-step;
  margin-bottom: 2rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  li {
    padding-left: 24px;
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 0.3em;
    }

    &::before {
      counter-increment: ol-step;
      content: counter(ol-step) '. ';
      position: absolute;
      left: 0;
    }
  }
}

.bullet-list {
  @extend %bullet-list;
}

.ordered-list {
  @extend %ordered-list;
}

.icon-list {
  @include spacing-m;

  > li {
    position: relative;
    padding-left: 38px;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid $border;

    &::before {
      content: '';
      background-image: svg-load('material/check_circle.svg', fill=$green);
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -16px;
      background-position: 50% 50%;
    }

    > span {
      display: inline-block;
      vertical-align: middle;
    }

    &:last-of-type {
      border-bottom: 1px solid $border;
    }
  }
}

.inline-list {
  > li {
    display: inline-block;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.blank-list {
  li {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 0.3em;
    }
  }
}
