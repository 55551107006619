// ==========================================================================
// Basic layout and grid classes
// ==========================================================================

.container {
  @include container;
  width: 100%;
}

.cf {
  @include cf;
}

.container-full-height {
  height: 100%;
  position: relative;
}

.pad {
  @include pad;
}

.row {
  @include row;
}

.flex-row {
  @include negative-margin;

  @include media('>phone') {
    display: flex;
  }

  > * {
    margin-left: $gutter / 2;
    margin-right: $gutter / 2;
    flex-basis: 0%;
    flex-shrink: 1;
    flex-grow: 1;

    > img {
      width: 100%;
      display: block;
    }

    @include media('<=phone') {
      &:not(:last-child) {
        margin-bottom: $gutter;
      }
    }
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }
}

@for $i from 1 through $ncol {
  .col-#{$i} {
    @include col($i);
  }
}

.max-16 {
  max-width: 1600px;
}

.max-14 {
  max-width: 1400px;
}

.max-12 {
  max-width: colw(12);
}

.max-10 {
  max-width: colw(10);
}

.max-8 {
  max-width: colw(8);
}

.max-6 {
  max-width: colw(6);
}

.max-5 {
  max-width: colw(5);
}

.max-4 {
  max-width: colw(4);
}

.max-3 {
  max-width: colw(3);
}

.dynamic-row {
  display: table;
  margin: 0 auto;
  table-layout: fixed;
}

.auto-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
}

.auto-col {
  display: table-cell;
  vertical-align: top;
}

.justify-row {
  @include negative-margin;
  font-size: 0;
  text-align: justify;

  &::after {
    content: '';
    width: 100%;
    height: 0;
    display: inline-block;
  }
}

.justify-col {
  @include pad;
  display: inline-block;
  font-size: 16px;
}

.justify-btn-row {
  margin-top: 16px;
  display: table;
  width: 100%;
  line-height: 1;

  @include media('<=phone') {
    display: block;

    .justify-btn-col,
    .btn {
      display: block;
      width: 100%;
    }

    .justify-btn-col + .justify-btn-col {
      margin-top: 8px;
    }
  }
}

.justify-btn-col {
  display: table-cell;
  text-align: left;
  vertical-align: top;

  &:last-of-type {
    text-align: right;
  }
}

.cell-fix {
  display: table-cell;
  float: none;
}

.narrow-row {
  margin-left: -($gutter / 4);
  margin-right: -($gutter / 4);
}

.narrow-col {
  padding-left: ($gutter / 4);
  padding-right: ($gutter / 4);
}

// Vertically align cells
.va-t,
.va-t > .auto-col {
  vertical-align: top;
}

// Break cells
.br-p,
.br-p > [class^='max-'],
.br-p > .auto-col {
  @include media('<=phone') {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.br-t,
.br-l > [class^='max-'],
.br-t > .auto-col {
  @include media('<=tablet') {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.br-l,
.br-l > .auto-col {
  @include media('<=laptop') {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.br-d,
.br-t > [class^='max-'],
.br-d > .auto-col {
  @include media('<=desktop') {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}
