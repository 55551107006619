.date-field {
  background-color: $white;
  background-image: svg-load('material/insert_invitation.svg', fill=$grey3);
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.child-age-selector-wrapper {
  display: flex;
  justify-content: flex-end;
}

.child-age-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-top: 10px;

  @include media('<500px') {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    width: 60%;
    color: $grey2;
  }

  select {
    width: 30%;
  }

  label,
  select {
    @include media('<500px') {
      width: 100%;
    }
  }
}

///// choices.js styles
.choices__item {
  &:not(.choices__item--choice) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    padding: 9px 12px;
    border: 1px solid $blue;
    border-radius: 3px;
    color: $blue;
  }

  .choices__button {
    width: 25px;
    height: 25px;
    background: transparent no-repeat scroll center center;
    border: none;
    text-indent: -9999px;
  }
}

.multiple-location-choices {
  position: relative;

  .choices__input {
    width: 100% !important;
    background: transparent no-repeat scroll;
    background-position: right 8px center;
    background-image: svg-load('arrow_drop_down.svg', fill=$grey3);
  }
}