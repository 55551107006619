// ==========================================================================
// All the buttons go here.
// ==========================================================================

.btn {
  display: inline-block;
  text-transform: uppercase;
  line-height: 1.3;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  padding: 13px 18px;
  user-select: none;
  overflow: hidden;
  text-align: center;
  min-width: 100px;
  white-space: nowrap;

  @include no-aa;

  &:hover {
    text-decoration: none;
  }
}

.btn-wide {
  min-width: 200px;
}

.btn-orange {
  background: $orange;
  border: 1px solid $orange;
  color: $white !important;

  &:hover {
    background: mix($orange, $white, 70%);
    border-color: mix($orange, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: #a5a5a5;
    border: 1px solid #a5a5a5;
  }
}

.btn-green {
  background: $green;
  border: 1px solid $green;
  color: $white !important;

  &:hover {
    background: mix($green, $white, 70%);
    border-color: mix($green, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: #a5a5a5;
    border: 1px solid #a5a5a5;
  }
}

.btn-blue {
  background: $blue;
  border: 1px solid $blue;
  color: $white !important;

  &:hover {
    background: mix($blue, $white, 70%);
    border-color: mix($blue, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: #a5a5a5;
    border: 1px solid #a5a5a5;
  }
}

.btn-orange-clear {
  background: rgba($white, 0);
  border: 1px solid $orange;
  color: $orange;

  &:hover {
    color: mix($orange, $white, 70%) !important;
    border-color: mix($orange, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: rgba(#a5a5a5, 0.2);
    border: 1px solid #a5a5a5;
  }
}

.btn-white-clear {
  background: rgba($white, 0);
  border: 1px solid $white;
  color: $white !important;

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    cursor: default;
    background: rgba(#a5a5a5, 0.2);
    border: 1px solid #a5a5a5;
  }
}

.btn-grey-clear {
  background: rgba($white, 0);
  border: 1px solid $grey3;
  color: $grey3;

  &:hover {
    color: mix($grey3, $white, 70%) !important;
    border-color: mix($grey3, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: rgba(#a5a5a5, 0.2);
    border: 1px solid #a5a5a5;
  }
}

.btn-blue-clear {
  background: rgba($white, 0);
  border: 1px solid mix($blue, $white, 70%);
  color: $white;

  &:hover {
    color: $white;
    background: mix($blue, $white, 70%);
    border-color: mix($blue, $white, 70%);
  }

  &[disabled] {
    cursor: default;
    background: rgba(#a5a5a5, 0.2);
    border: 1px solid #a5a5a5;
  }
}

// Just a simple link

.btn-link {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: auto;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  background: rgba(0, 0, 0, 0);
  color: $orange;
  border-width: 0;
  padding: 0;
  vertical-align: baseline;
  text-align: left;
  display: inline-block;
  min-width: 0;
  appearance: none;
  cursor: pointer;
}

.loading {
  position: relative;

  &::before {
    content: '';
    background-image: svg-load('spinner-solid.svg');
    color: $white;
    width: 30px;
    height: 30px;
    transform-origin: center;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 8px;
    right: 8px;
    animation: rotate 1.4s linear infinite;
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
