.hdr-searchbar-open,
.search-form-dropdown-open {
  @include media('<=tablet') {
    position: relative;
    overflow: hidden;
    height: 100%;

    body {
      height: 100%;
      overflow: hidden;
    }

    .hero-home {
      z-index: 35;
    }
  }
}

.hdr-searchbar-open {
  @include media('<=tablet') {
    .search-form-container {
      display: block;
    }

    .hdr-searchbar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 120;
    }
  }
}

.search-form-dropdown-open {
  .search-form-dropdown {
    display: block;
  }

  .sfi-guests .pseudo-select {
    outline: none;
    box-shadow: 0 0 0 1px #309a3c;
  }
}

@mixin dropdown-modal() {
  background: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: auto;
  height: auto;
  will-change: transform;
}

// ==========================================================================
// Header Searchbar
// ==========================================================================

.hdr-searchbar {
  z-index: 100;
  padding: ($gutter / 2) 0;
  will-change: transform, background-color, box-shadow;
  transition: background-color 150ms ease, box-shadow 150ms ease;

  @include media('>tablet') {
    padding: 0 0 ($gutter / 2);
  }

  // > .container {
  //   padding: 0 ($gutter / 2) - 3px;
  // }

  select,
  .search-date-cell-inner,
  .pseudo-input,
  input,
  [type='text'] {
    background-color: $sand !important;
  }
}

#id_promo_code_container {
  input {
    background: $white;
  }
}

.fixed-hdr-sb {
  .hdr {
    padding-bottom: 63px;

    @include media('>tablet') {
      padding-bottom: 128px;
    }

    @include media('>laptop') {
      padding-bottom: 70px;
    }
  }

  .hdr-searchbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 150;
    will-change: transform;
    box-shadow: 0 3px 6px rgba($black, 0.16);
    background-color: $white;
  }
}

html:not(.nosscroll):not(.fixed-hdr-sb) {
  .hdr-searchbar {
    margin-right: 0 !important;
  }
}

.search-date-input {
  text-align: center;
  background-color: rgba(0, 0, 0, 0) !important;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $green;
  }
}

.search-form-container {
  margin: 0 -3px;
  display: none;

  @include media('>tablet') {
    display: block;
  }

  .hero & {
    display: block;
  }

  .hdr & {
    @include media('<=tablet') {
      @include dropdown-modal;

      > .auto-row {
        padding: 0 ($gutter / 2) ($gutter / 2);
      }
    }
  }

  .auto-row {
    table-layout: auto;
  }
}

.search-form-inner {
  @include media('<=tablet') {
    .hdr & {
      position: absolute;
      overflow: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: $gutter / 2;
    }

    .search-form-header + & {
      top: 60px;
    }
  }
}

.search-form-mTrigger {
  @include media('>tablet') {
    display: none;
  }

  > button {
    color: #fff;
    width: 100%;
    border: 0;

    // @include media('<=tablet') {
    // height: 39px;
    // }

    > .icon {
      margin-top: -2px;
      vertical-align: middle;
    }
  }
}

.search-form-header {
  position: relative;
  background: $orange;
  padding-right: 54px;
  color: #fff;

  @include cf;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  text-align: left;
  padding-left: $gutter / 2;

  @include media('>tablet') {
    display: none;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: none;
    width: 60px;
    height: 100%;
    border: none;
    cursor: pointer;
    text-align: center;
    padding: 15px;

    svg {
      display: block;
    }
  }
}

.search-form-item {
  padding: 0 3px;
  padding-top: 12px;

  &:not(:last-of-type) {
    padding-right: 6px;
  }

  > input,
  > select,
  > button,
  > a {
    display: block;
    width: 100%;
  }

  > input,
  > select {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px $green;
    }
  }

  > button {
    padding-left: 4px;
    padding-right: 4px;
  }
}

#searchBtn {
  height: 46px;
}

#id_promo_code {
  width: 100%;
  display: block;
}

#id_camp {
  &[disabled] {
    color: #aaa !important;
    background-color: #eaeaea !important;
  }
}

#id_camp_desktop {
  display: none;
  position: absolute;
  top: 12px;
  left: 3px;
  right: 3px;
  bottom: 0;

  .no-touchevents & {
    @include media('>tablet') {
      display: block;
    }
  }

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px #309a3c;
    }
  }
}

// ==========================================================================
// I'm sorry. I had to do it.
// ==========================================================================

.sfi-location-types {
  .auto-row {
    table-layout: fixed;
  }
}

.sfi-types {
  @include media('>laptop') {
    width: 45%;
  }
}

.sfi-location {
  .search-form-item {
    position: relative;
  }
}

.sfi-d-g-p-s {
  @include media('>laptop') {
    width: 675px;
  }
}

.sfi-date {
  @include media('>phone') {
    width: 265px;
  }
}

.sfi-guests {
  @include media('>phone') {
    width: 135px;
  }
}

.sfi-promo {
  @include media('>phone') {
    width: 185px;
  }

  .clear-input {
    padding: 10px;
    z-index: 2;
    color: $grey3;

    .icon {
      vertical-align: middle;
    }
  }
}

.sfi-promo-error {
  background: $yellow;
  border-radius: 3px;
  padding: 10px;
  white-space: nowrap;

  @include shadow2;
  margin-top: 5px;
  font-size: 14px;
  color: $grey1;
  display: none;

  @include media('>phone') {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
  }

  .has-error &,
  .has-error + & {
    display: block;
  }
}

.promo-valid-tooltip {
  background: $yellow;
  border-radius: 3px;
  padding: 10px;
  white-space: nowrap;

  @include shadow2;
  margin-top: 5px;
  font-size: 14px;
  color: $grey1;
  display: none;

  @include media('>phone') {
    position: absolute;
    z-index: 2;
    top: 100%;
    right: 0;
  }
}

.promo-valid {
  & ~ .promo-valid-tooltip {
    display: block !important;
  }
}

.sfi-submit {
  padding-top: 12px;

  @include media('>phone') {
    padding-top: 0;
    width: 160px;
  }
}

.search-date-cell {
  position: relative;
}

.search-date-cell-inner {
  @include cf;
  width: 100%;
  border: 1px solid $border;
  border-radius: 3px;
  background-color: $sand;
  background-size: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: svg-load('material/arrow_forward.svg', fill=$grey2);

  .hero & {
    background-color: $white !important;
  }

  .search-date-input {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.search-form-item-mLabel {
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  color: #969696;

  .hero & {
    color: #fff;
  }

  @include media('>phone') {
    @include sr-only;
  }
}

.search-date-field {
  float: left;
  width: 50%;
  position: relative;
}

input.search-date-input {
  width: 100%;
  border: 0;
  height: 44px;
}

.pseudo-input {
  height: 46px;
  border-radius: 3px;
  border: 1px solid $border;
  padding: 10px 12px;
  position: relative;
  color: $grey2;
  cursor: pointer;
  background: $white;

  @include media('<=phone') {
    text-align: left;
  }
}

.pseudo-select {
  @extend %select;
}

.search-form-dropdown {
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 50%;
  width: 240px;
  margin-top: 27px;
  margin-left: -120px;
  background: #fff;
  display: none;

  @include media('<=tablet') {
    @include dropdown-modal;
  }
}

.search-form-dropdown-inner {
  background: #fff;

  @include media('>tablet') {
    @include shadow2;
  }

  @include media('<=tablet') {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .search-form-header + & {
      top: 60px;
    }
  }
}

.guests-cell {
  > div {
    position: relative;
    outline: none;
  }

  .pseudo-input {
    text-align: left;

    span {
      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }
}

.guests-cell-field {
  @include cf;

  &.cta-cont {
    padding: 13px ($gutter / 2);
  }

  label,
  input,
  select,
  .pos-r {
    float: left;
    height: 49px;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  label {
    text-align: left;
    line-height: 49px;
    padding: 0 11px;
    width: 42%;
    font-size: 15px;
    color: #969696;
  }

  input,
  select,
  .pos-r {
    width: 58%;
    color: $blue;
  }

  .pos-r {
    position: relative;

    label,
    input,
    select {
      width: 100%;
    }
  }

  button {
    display: block;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  fieldset {
    position: relative;
    display: none;
    width: 100%;

    &::before,
    &::after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
    }

    &::before {
      top: -6px;
      left: 32px;
      border-width: 0 6px;
    }

    &::after {
      top: -4px;
      left: 33px;
      border-width: 0 5px;
    }

    label {
      background-color: #f6fbff;
    }

    &.has-items {
      display: block;
      margin-bottom: -1px;
      border-bottom: 1px solid #0085ff;

      &::before {
        border-width: 0 6px 7px;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0085ff;
      }

      &::after {
        border-width: 0 5px 6px;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f5f5f5;
      }
    }

    &.has-items .guests-cell-field:first-child {
      border-color: #0085ff;
    }

    .js-dgi-child-item {
      background-color: #eef7ff !important;
    }
  }

  &.field-with-error {
    label {
      background-color: transparent;
      color: #ff0000;
    }

    .js-dgi-child-item {
      background-color: #ffe8e8 !important;
    }

    ::placeholder {
      color: #ff0000;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #ff0000;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #ff0000;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #ff0000;
    }
  }
}

.guests-cell-field + .guests-cell-field,
.guests-cell-field .guests-cell-field:first-child {
  border-top: solid 1px #d5d5d5;
}

.prevent-touch {
  .no-touchevents & {
    display: none;
  }

  @include divfill;
  background: $blue;
  opacity: 0;
  z-index: 1;
}
